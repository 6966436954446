import React, { Component } from "react"
import PropTypes from "prop-types";
import {
  Col,
  Card,
  Nav,
  CardBody,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
} from "reactstrap"
import classnames from "classnames"

//Simple bar
import SimpleBar from "simplebar-react"
import { withTranslation } from "react-i18next"

class TradeHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
    }
    this.toggleTab = this.toggleTab.bind(this)
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  renderOrders(orders) {
    return (
      <React.Fragment>
        <SimpleBar style={{ maxHeight: "330px" }}>
          <div className="table-responsive">
            <Table className="align-middle table-nowrap">
              <tbody>
                {orders && orders.length ?
                  orders.map(order => {
                    return (
                      <tr key={order.id}>
                        <td style={{ width: "50px" }}>
                          <div className="font-size-22 text-primary">
                            <i className="bx bx-down-arrow-circle" />
                          </div>
                        </td>

                        <td>
                          <div>
                            <h5 className="font-size-14 mb-1">{order.side} {order.info?.symbol?.split('-')?.[0]}</h5>
                            <p className="text-muted mb-0">{new Date(order.datetime)?.toLocaleString()}</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-14 mb-0">{order.amount} {order.info?.symbol?.split('-')?.[0]}</h5>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-14 text-muted mb-0"></h5>
                          </div>
                        </td>
                      </tr>
                    )
                  }) : (
                    <tr>
                      <td style={{ width: "50px" }}>
                        <div className="font-size-22 text-primary">
                          <i className="bx bx-down-arrow-circle" />
                        </div>
                      </td>

                      <td>
                        <div>
                          <p className="text-muted mb-0">{this.props.t("No Orders")}</p>
                        </div>
                      </td>

                      <td>
                        <div className="text-end">
                          <h5 className="font-size-14 mb-0">--</h5>
                        </div>
                      </td>

                      <td>
                        <div className="text-end">
                          <h5 className="font-size-14 text-muted mb-0">--</h5>
                        </div>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </Table>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  }

  render() {
    const { trades } = this.props

    return (
      <React.Fragment>
        <Col xs="12">
          <Card>
            <CardBody>
              <h4 className="card-title mb-4">{this.props.t("Order History")}</h4>

              {this.props.loading ? (
                <p>{this.props.t("Loading...")}</p>
              ) : (
                <React.Fragment>
                  <Nav pills className="bg-light rounded" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1",
                        })}
                        onClick={() => {
                          this.toggleTab("1")
                        }}
                      >
                        {this.props.t("All")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggleTab("2")
                        }}
                      >
                        {this.props.t("Buy")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "3",
                        })}
                        onClick={() => {
                          this.toggleTab("3")
                        }}
                      >
                        {this.props.t("Sell")}
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={this.state.activeTab} className="mt-4">
                    <TabPane tabId="1">
                      {this.renderOrders(trades)}
                    </TabPane>
                    <TabPane tabId="2">
                      {this.renderOrders(trades?.filter(order => order.side == this.props.t("BUY")))}
                    </TabPane>
                    <TabPane tabId="3">
                      {this.renderOrders(trades?.filter(order => order.side == this.props.t("SELL")))}
                    </TabPane>
                  </TabContent>
                </React.Fragment>
              )}

            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

TradeHistory.propTypes = {
  t: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  trades: PropTypes.any,
  loading: PropTypes.bool
}


export default withTranslation()(TradeHistory)
