

export const getBalance = (api_id, onSuccess, onError) => ({
  type: 'GET_BALANCE',
  payload: api_id,
  onSuccess,
  onError
})

export const getBalanceSuccess = balance => ({
  type: 'GET_BALANCE_SUCCESS',
  payload: balance,
})

export const getBalanceFail = error => ({
  type: 'GET_BALANCE_FAIL',
  payload: error,
})



export const getPrices = (payload, onSuccess, onError) => ({
  type: 'GET_PRICES',
  payload: payload,
  onSuccess,
  onError
})

export const getPricesSuccess = prices => ({
  type: 'GET_PRICES_SUCCESS',
  payload: prices,
})



export const forgotPassword = (params, onSuccess, onError) => ({
  type: 'FORGOT_PASSWORD',
  payload: params,
  onSuccess,
  onError
})

export const setPassword = (params, onSuccess, onError) => ({
  type: 'SET_PASSWORD',
  payload: params,
  onSuccess,
  onError
})

export const changePassword = (params, onSuccess, onError) => ({
  type: 'CHANGE_PASSWORD',
  payload: params,
  onSuccess,
  onError
})



export const enable2fa = (onSuccess, onError) => ({
  type: 'ENABLE_2FA',
  onSuccess,
  onError
})

export const verify2fa = (code, onSuccess, onError) => ({
  type: 'VERIFY_2FA',
  payload: code,
  onSuccess,
  onError
})

export const toggle2faSuccess = () => ({
  type: 'TOGGLE_2FA_SUCCESS',
})

export const disable2fa = (onSuccess, onError) => ({
  type: 'DISABLE_2FA',
  onSuccess,
  onError
})



export const getPlans = (onSuccess, onError) => ({
  type: 'GET_PLANS',
  onSuccess,
  onError
})

export const getPlansSuccess = plans => ({
  type: 'GET_PLANS_SUCCESS',
  payload: plans
})



export const getSubscriptionHistory = (onSuccess, onError) => ({
  type: 'GET_SUBSCRIPTION_HISTORY',
  onSuccess,
  onError
})

export const getSubscriptionHistorySuccess = invoices => ({
  type: 'GET_SUBSCRIPTION_HISTORY_SUCCESS',
  payload: invoices
})



export const subscribe = (planID, onSuccess, onError) => ({
  type: 'SUBSCRIBE',
  payload: planID,
  onSuccess,
  onError
})

export const subscribeSuccess = (planID) => ({
  type: 'SUBSCRIBE_SUCCESS',
  payload: planID,
})



export const initializeBot = (planID, onSuccess, onError) => ({
  type: 'INITIALIZE_BOT',
  payload: planID,
  onSuccess,
  onError
})



export const changePlan = (planID, onSuccess, onError) => ({
  type: 'CHANGE_PLAN',
  payload: planID,
  onSuccess,
  onError
})

export const unsubscribe = (onSuccess, onError) => ({
  type: 'UNSUBSCRIBE',
  onSuccess,
  onError
})



export const createWallet = (onSuccess, onError) => ({
  type: 'CREATE_WALLET',
  onSuccess,
  onError
})



export const withdraw = (params, onSuccess, onError) => ({
  type: 'WITHDRAW',
  payload: params,
  onSuccess,
  onError
})



export const getTransactionHistory = (coin, onSuccess, onError) => ({
  type: 'GET_TRANSACTION_HISTORY',
  payload: coin,
  onSuccess,
  onError
})

export const getTransactionHistorySuccess = history => ({
  type: 'GET_TRANSACTION_HISTORY_SUCCESS',
  payload: history,
})



export const getReferrals = (onSuccess, onError) => ({
  type: 'GET_REFERRALS',
  onSuccess,
  onError
})

export const getReferralsSuccess = referrals => ({
  type: 'GET_REFERRALS_SUCCESS',
  payload: referrals
})
export const getReferralWithdrawals = (onSuccess, onError) => ({
  type: 'GET_REFERRAL_WITHDRAWALS',
  onSuccess,
  onError
})

export const getReferralWithdrawalsSuccess = referrals => ({
  type: 'GET_REFERRALS_WITHDRAWALS_SUCCESS',
  payload: referrals
})
export const getReferralEarnings = (onSuccess, onError) => ({
  type: 'GET_REFERRAL_EARNINGS',
  onSuccess,
  onError
})

export const getReferralEarningsSuccess = referrals => ({
  type: 'GET_REFERRALS_EARNINGS_SUCCESS',
  payload: referrals
})

export const withdrawReferralPayout = (params, onSuccess, onError) => ({
  type: 'WITHDRAW_REFERRAL_PAYOUT',
  payload: params,
  onSuccess,
  onError
})


export const autoRenewBot = (bot, onSuccess, onError) => ({
  type: 'AUTO_RENEW_BOT',
  payload: bot,
  onSuccess,
  onError
})

export const autoRenewBotSuccess = bot => ({
  type: 'AUTO_RENEW_BOT_SUCCESS',
  payload: bot,
})

export const autoRenewBotFail = error => ({
  type: 'AUTO_RENEW_BOT_FAIL',
  payload: error,
})



export const verifyTransfer = (code, onSuccess, onError) => ({
  type: 'VERIFY_TRANSFER',
  payload: code,
  onSuccess,
  onError
})
