import {
  GET_BOTS_SUCCESS,
  GET_BOTS_FAIL,
  GET_COINS_SUCCESS,
  GET_COINS_FAIL,
  GET_PAIRS_SUCCESS,
  GET_PAIRS_FAIL,
  CREATE_BOT_SUCCESS,
  CREATE_BOT_FAIL,
  EDIT_BOT_SUCCESS,
  EDIT_BOT_FAIL,
  START_STOP_BOT_SUCCESS,
  START_STOP_BOT_FAIL,
  UPDATE_BOT_SUCCESS,
  UPDATE_BOT_FAIL,
  DELETE_BOT_SUCCESS,
  DELETE_BOT_FAIL,
  GET_OPEN_ORDERS_SUCCESS,
  GET_OPEN_ORDERS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  bots: [],
  coins: [],
  pairs: [],
  error: {},
}

const Bots = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_BOTS_SUCCESS:
      return {
        ...state,
        bots: action.payload,
      }

    case GET_BOTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case GET_COINS_SUCCESS:
      return {
        ...state,
        coins: action.payload,
      }

    case GET_COINS_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case GET_PAIRS_SUCCESS:
      return {
        ...state,
        pairs: action.payload,
      }

    case GET_PAIRS_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case EDIT_BOT_SUCCESS:
      return {
        ...state,
        bots: [...state.bots, action.payload],
      }

    case EDIT_BOT_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case START_STOP_BOT_SUCCESS:
      return {
        ...state,
        bots: state.bots.map(bot =>
          bot._id == action.payload._id ?
            { ...bot, status: !bot.status } : bot
        ),
      }

    case START_STOP_BOT_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case UPDATE_BOT_SUCCESS:
      return {
        ...state,
        bots: state.bots.map(bot =>
          bot._id == action.payload.id ?
            { ...bot, status: !bot.status } : bot
        ),
      }

    case UPDATE_BOT_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case DELETE_BOT_SUCCESS:
      return {
        ...state,
        bots: state.bots.filter(bot => bot._id != action.payload._id)
      }

    case DELETE_BOT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case CREATE_BOT_SUCCESS:
      return {
        ...state,
        bots: state.bots
      }

    case CREATE_BOT_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case GET_OPEN_ORDERS_SUCCESS:
      return {
        ...state,
        bots: state.bots.map(bot =>
          bot._id == action.botID ?
            { ...bot, open_orders: action.payload } : bot
        ),
      }

    case GET_OPEN_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }



    case 'GET_TRADE_HISTORY_SUCCESS':
      return {
        ...state,
        bots: state.bots.map(bot =>
          bot._id == action.botID ?
            { ...bot, trade_history: action.payload?.status } : bot
        ),
      }

    case 'GET_TRADE_HISTORY_FAIL':
      return {
        ...state,
        error: action.payload,
      }




    default:
      return state
  }
}

export default Bots
