import React, { Component } from "react"
import { Row, Col } from "reactstrap"
import PropTypes from 'prop-types'
import { withTranslation } from "react-i18next"

// const Footer = ({auth, parentRef}) => {

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <React.Fragment>
        <footer className={this.props.auth ? "auth-footer" : "footer"}>
          <div className="container-fluid">
            <Row>
              <Col sm="4">{new Date().getFullYear()} © Atlantis.</Col>
              <Col sm="4" className="text-center">
                <a href="https://support.atlantisgold.trade" rel="noreferrer" target="_blank">{this.props.t("Support")}</a>
              </Col>
              <Col sm="4">
                <div className="text-sm-end d-none d-sm-block">
                  {this.props.t("All Rights Reserved")}
                </div>
              </Col>
            </Row>
          </div>
        </footer>
      </React.Fragment>
    )
  }
}


Footer.propTypes = {
  t: PropTypes.any,
  auth: PropTypes.any
}

export default withTranslation()(Footer)