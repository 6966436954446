import { call, put, takeEvery } from "redux-saga/effects"

import {
  getBalanceSuccess,
  getBalanceFail,
  getPricesSuccess,
  toggle2faSuccess,
  subscribeSuccess,
  initializeBotSuccess,
  getPlansSuccess,
  getReferralsSuccess,
  getReferralWithdrawalsSuccess,
  getReferralEarningsSuccess,
  getTransactionHistorySuccess,
  getSubscriptionHistorySuccess,
  autoRenewBotSuccess,
  autoRenewBotFail
} from "./actions"

import { get, post, apiPut, stub } from "helpers/api_helper"


function* fetchBalance({ payload: api_id, onSuccess, onError }) {
  try {
    const response = yield call(post, 'exchanges/balance', { api_id: api_id })
    yield put(getBalanceSuccess(response.data))
    if (onSuccess) onSuccess(response)
  } catch (error) {
    console.log("fetchBalance called error" , error);
    yield put(getBalanceFail(error.response?.data?.msg))
    if (onError) onError(error.response)
  }
}



function* fetchPrices({ payload, onSuccess, onError }) {
  console.log("pppppp",payload)
  try {
    const response = yield call(post, 'exchanges/getCoinPrice', payload)
    yield put(getPricesSuccess(response.data))
    if (onSuccess) onSuccess(response)
  } catch (error) {
    if (onError) onError(error.response)
  }
}



function* forgotPassword({ payload: params, onSuccess, onError }) {
  try {
    const response = yield call(post, 'users/forgotPassword', params)
    onSuccess(response)
  } catch (error) {
    onError(error.response)
  }
}

function* setPassword({ payload: params, onSuccess, onError }) {
  try {
    const response = yield call(apiPut, 'users/setPassword', params)
    if (onSuccess) onSuccess(response)
  } catch (error) {
    if (onError) onError(error?.response)
  }
}

function* changePassword({ payload: params, onSuccess, onError }) {
  try {
    const response = yield call(apiPut, 'users/password', params)
    onSuccess(response)
  } catch (error) {
    onError(error.response)
  }
}



function* enable2fa({ onSuccess, onError }) {
  try {
    const response = yield call(get, 'users/enable2Factor')
    onSuccess(response)
  } catch (error) {
    onError(error.response)
  }
}

function* verify2fa({ payload: code, onSuccess, onError }) {
  try {
    const response = yield call(post, 'users/verify2Factor', { token_code: code })
    onSuccess(response)
  } catch (error) {
    onError(error.response)
  }
}

function* disable2fa({ onSuccess, onError }) {
  try {
    const response = yield call(get, 'users/disable2Factor')
    yield put(toggle2faSuccess())
    onSuccess(response)
  } catch (error) {
    onError(error.response)
  }
}



function* getPlans({ onSuccess, onError }) {
  try {
    const response = yield call(post, 'payments/plans', {})
    if (onSuccess) onSuccess(response)
    yield put(getPlansSuccess(response?.data))
  } catch (error) {
    if (onError) onError(error?.response)
  }
}



function* subscribe({ payload: params, onSuccess, onError }) {
  try {
    const response = yield call(post, 'payments/botSubscription', params)
    // yield put(subscribeSuccess(response?.data))
    onSuccess(response)
  } catch (error) {
    onError(error.response)
  }
}

function* initializeBot({ payload: params, onSuccess, onError }) {
  try {
    const response = yield call(post, 'payments/initialiseBot', params)
    onSuccess(response)
  } catch (error) {
    onError(error.response)
  }
}

function* changePlan({ onSuccess, onError }) {
  try {
    const response = yield call(stub, {
      msg: 'Changed Plan!'
    }, 1000)
    onSuccess(response)
  } catch (error) {
    onError(error.response)
  }
}

function* unsubscribe({ onSuccess, onError }) {
  try {
    const response = yield call(stub, {
      msg: 'Unsubscribed!'
    }, 1000)
    onSuccess(response)
  } catch (error) {
    onError(error.response)
  }
}



function* getSubscriptionHistory({ onSuccess, onError }) {
  try {
    const response = yield call(post, 'payments/invoices', {})
    if (onSuccess) onSuccess(response)
    yield put(getSubscriptionHistorySuccess(response?.data))
  } catch (error) {
    if (onError) onError(error.response)
  }
}



function* createWallet({ onSuccess, onError }) {
  try {
    const response = yield call(post, 'address/create/PDT', {})
    onSuccess(response)
  } catch (error) {
    onError(error?.response)
  }
}



function* withdraw({ payload: params, onSuccess, onError }) {
  try {
    const response = yield call(post, 'transactions/pdt', params)
    onSuccess(response)
  } catch (error) {
    onError(error?.response)
  }
}



function* getTransactionHistory({ payload: coin, onSuccess, onError }) {
  try {
    const response = yield call(post, 'transactions/myHistory', { wallet_type: coin })
    // onSuccess(response)
    yield put(getTransactionHistorySuccess(response?.data))
  } catch (error) {
    // onError(error?.response)
  }
}



function* getReferrals({ onSuccess, onError }) {
  try {
    const response = yield call(post, 'users/referralData', {})
    if (onSuccess) onSuccess(response)
    yield put(getReferralsSuccess(response))
  } catch (error) {
    if (onError) onError(error?.response)
  }
}
function* getReferralWithdrawals({ onSuccess, onError }) {
  try {
    const response = yield call(post, 'users/referralWithdraw', {})
    if (onSuccess) onSuccess(response)
    yield put(getReferralWithdrawalsSuccess(response))
  } catch (error) {
    if (onError) onError(error?.response)
  }
}
function* getReferralEarnings({ onSuccess, onError }) {
  try {
    const response = yield call(post, 'users/referralEarning', {})
    if (onSuccess) onSuccess(response)
    yield put(getReferralEarningsSuccess(response))
  } catch (error) {
    if (onError) onError(error?.response)
  }
}



function* withdrawReferralPayout({ payload: params, onSuccess, onError }) {
  try {
    const response = yield call(post, 'users/profitsWithdrawl', params)
    if (onSuccess) onSuccess(response)
  } catch (error) {
    if (onError) onError(error?.response)
  }
}

function* onAutoRenew({ payload: bot, onSuccess, onError }) {
  try {
    const response = yield call(post, 'exchanges/autoRenew', { id: bot._id })
    onSuccess({ ...response, bot })
    yield put(autoRenewBotSuccess(bot))
  } catch (error) {
    onError(error.response)
    yield put(autoRenewBotFail(error))
  }
}


function* verifyTransfer({ payload: code, onSuccess, onError }) {
  try {
    const response = yield call(post, 'transactions/updateRequest', { verification_code: code })
    if (onSuccess) onSuccess(response)
  } catch (error) {
    if (onError) onError(error?.response)
  }
}


function* userSaga() {
  yield takeEvery('GET_BALANCE', fetchBalance)
  yield takeEvery('GET_PRICES', fetchPrices)
  yield takeEvery('FORGOT_PASSWORD', forgotPassword)
  yield takeEvery('SET_PASSWORD', setPassword)
  yield takeEvery('CHANGE_PASSWORD', changePassword)
  yield takeEvery('ENABLE_2FA', enable2fa)
  yield takeEvery('VERIFY_2FA', verify2fa)
  yield takeEvery('DISABLE_2FA', disable2fa)
  yield takeEvery('GET_PLANS', getPlans)
  yield takeEvery('SUBSCRIBE', subscribe)
  yield takeEvery('INITIALIZE_BOT', initializeBot)
  yield takeEvery('CHANGE_PLAN', changePlan)
  yield takeEvery('UNSUBSCRIBE', unsubscribe)
  yield takeEvery('GET_SUBSCRIPTION_HISTORY', getSubscriptionHistory)
  yield takeEvery('CREATE_WALLET', createWallet)
  yield takeEvery('WITHDRAW', withdraw)
  yield takeEvery('GET_TRANSACTION_HISTORY', getTransactionHistory)
  yield takeEvery('GET_REFERRALS', getReferrals)
  yield takeEvery('GET_REFERRAL_WITHDRAWALS', getReferralWithdrawals)
  yield takeEvery('GET_REFERRAL_EARNINGS', getReferralEarnings)
  yield takeEvery('WITHDRAW_REFERRAL_PAYOUT', withdrawReferralPayout)
  yield takeEvery('AUTO_RENEW_BOT', onAutoRenew)
  yield takeEvery('VERIFY_TRANSFER', verifyTransfer)
}

export default userSaga
