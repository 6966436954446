import React, { Component } from "react"
import { BrowserRouter as Router, Switch, Redirect, Route } from "react-router-dom"

import { authProtectedRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"

import Navigation from "./navigation/Navigation"

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"

import "./assets/scss/theme.scss"

import axios from "axios"

// import fakeBackend from "./helpers/AuthType/fakeBackend"
// fakeBackend()

import { getMe } from "store/actions"
// import { setIP } from "helpers/api_helper"



class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      userFetched: false,
    }

    this.changeUserFetched = this.changeUserFetched.bind(this)
    this.onGetMeSuccess = this.onGetMeSuccess.bind(this)
    this.onGetMeError = this.onGetMeError.bind(this)

    // console.log("window.localStorage.getItem('token') ", window.localStorage.getItem('token'));
    if (window.localStorage.getItem('token')) {
      // axios.get('https://ipapi.co/json/').then(response => {
        // console.log('got ip:', response.data.ip);
        // setIP(response.data.ip)
        this.props.onGetMe(this.onGetMeSuccess, this.onGetMeError)
      // });
    } else {
      this.changeUserFetched(true);
    }
  }

  onGetMeSuccess() {
    this.changeUserFetched(true);
  }

  onGetMeError(response) {
    this.changeUserFetched(true);
  }

  changeUserFetched(status){
    setTimeout(() => {
      this.setState({ userFetched: status })
    }, 1000);
  }


  render() {
    // console.log("this.state.userFetched ", this.state.userFetched);
    return this.state.userFetched ? (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={null}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {this.state.userFetched && !this.props.user ? (
              <Redirect to="/login" />
            ) : ''}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Navigation}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
            <Redirect to="/bot-list" />
          </Switch>
        </Router>
      </React.Fragment>
    ) : ''
  }
}

App.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
  onGetMe: PropTypes.func,
}

const mapStateToProps = state => ({
  user: state.auth.user,
})

const mapDispatchToProps = dispatch => ({
  onGetMe: (onSuccess, onError) => dispatch(getMe(onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(App)))
