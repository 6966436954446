import React, { Component } from "react"
import MetaTags from 'react-meta-tags'
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  ButtonToggle
} from "reactstrap"

import Switch from "react-switch";

import PropTypes from 'prop-types'

import Breadcrumbs from "navigation/Breadcrumb"

import CardPricing from "./card-pricing"
import SubscriptionHistory from "./subscriptionHistory"

import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import {
  getPlans,
  getBots,
  getSubscriptionHistory,
  autoRenewBot
} from "store/actions"
// import plans from './plans'


class Subscription extends Component {

  constructor(props) {
    super(props)
    this.state = {
      subscribedBots: []
    }
    this.onConfirmAutoRenewBot = this.onConfirmAutoRenewBot.bind(this)
    this.onAutoRenewSuccess = this.onAutoRenewSuccess.bind(this)
    this.onAutoRenewError = this.onAutoRenewError.bind(this)
  }

  componentDidMount() {
    this.props.onGetPlans()
    this.props.onGetSubscriptionHistory()
    this.props.onGetBots((bots) => {
      let sbots = bots.data?.filter(bot => bot.is_initialised == true && bot.subscription_expiry);

      this.setState({ subscribedBots: sbots })
    })
  }

  toggleAutoRenew = (bot, value) => {
    bot.auto_renew = value;
    this.onConfirmAutoRenewBot(bot);

  }

  onConfirmAutoRenewBot(bot) {
    this.props.onAutoRenewBot(bot, this.onAutoRenewSuccess, this.onAutoRenewError)
  }

  onAutoRenewSuccess(response) {
    let localsubscribedBots = this.state.subscribedBots;
    localsubscribedBots.map((item, index) => {
      if (response.bot._id == item._id) {
        localsubscribedBots[index].auto_renew = response.bot.auto_renew;
      }

    })
    this.setState({ subscribedBots: localsubscribedBots });
  }

  onAutoRenewError(response) {
    if (!response) {
      this.setState({ error: this.props.t("Connection Error") })
    }
    else {
      this.setState({ error: response.error })
    }
  }

  render() {
    const { plans } = this.props
    let planID = this.props.user?.subscription_status

    let initializedBots = this.props.bots?.filter(bot => bot.is_initialised == true && !bot.subscription_expiry)
    // let subscribedBots = this.props.bots?.filter(bot => bot.is_initialised == true && bot.subscription_expiry)
    // console.log("subscribedBots ", subscribedBots);
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Atlantis | Pricing")}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title={this.props.t("Subscription")}
              parent="/subscription"
            />

            {/*
              <React.Fragment>
                <Card className="mx-auto mb-5" style={{ maxWidth: '480px' }}>
                  <CardBody className="p-4">
                    <p className="text-center">Current Account Status:</p>
                    <h5 className="text-center m-0 text-success">{plans[planID]?.title}</h5>
                  </CardBody>
                </Card>

                <Row className="justify-content-center">
                  <Col lg={6}>
                    <div className="text-center mb-5">
                      <h4>Change Your Plan</h4>
                      <p className="text-muted"></p>
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            */}

            <h4 className="text-center mb-3">{this.props.t("Initialize")}</h4>
            <p className="text-center text-muted">{this.props.t("A one time Initialization Fee is required for each bot.")}</p>

            <Card style={{ maxWidth: '600px', margin: 'auto' }}>
              <CardBody>
                <div className="d-flex-column text-center">
                  <p className="mb-1">{this.props.t("One Time Initialization Fee")}</p>
                  <h5>{this.props.pricingInfo?.initial_fee?.toLocaleString() + ' PDT'}</h5>
                </div>
                <div className="mt-4 d-flex-column text-center">
                  <p className="text-primary mb-1">{this.props.t("With a Bot Trading PDT (-50%)")}</p>
                  <h3 className="text-primary">{(this.props.pricingInfo?.initial_fee / 2)?.toLocaleString() + ' PDT'}</h3>
                </div>
                <Button
                  onClick={() => this.props.history.push('/initialize')}
                  color="primary"
                  className="btn btn-primary btn-sm d-block m-auto mt-3"
                >
                  {this.props.t("Initialize A Bot")}
                </Button>
              </CardBody>
            </Card>

            {initializedBots?.length ? (
              <React.Fragment>
                <div className="d-flex mt-5 mb-4 align-items-center justify-content-center">
                  <i className="d-inline bx bx-check-circle font-size-24 text-success" />
                  <h5 className="mb-0 ms-2 text-success">{this.props.t("Inititialized Bots")}</h5>
                </div>
                {initializedBots?.map(bot => {
                  return (
                    <Card style={{ maxWidth: '600px', margin: 'auto' }} key={bot._id}>
                      <CardBody className="d-flex justify-content-between">
                        <p className="mb-0 fw-bold">{bot.name}</p>
                        <p className="mb-0 text-info">{this.props.t("Created on")} {new Date(bot.created_date)?.toLocaleString()}</p>
                      </CardBody>
                    </Card>
                  )
                })}
                <div style={{ height: '2rem' }}></div>
              </React.Fragment>
            ) : ''}

            <h4 className="text-center mt-5 mb-3">{initializedBots?.length ? this.props.t("Subscribe") : this.props.t("Choose Your Pricing Plan")}</h4>
            <p className="text-center text-muted">{this.props.t("After Initialization, you can pay your subscription fee via the following plans")}</p>

            {this.state.subscribedBots?.length ? (
              <React.Fragment>
                <div className="d-flex mt-5 mb-4 align-items-center justify-content-center">
                  <i className="d-inline bx bx-check-circle font-size-24 text-success" />
                  <h5 className="mb-0 ms-2 text-success">{this.props.t("Subscribed Bots")}</h5>
                </div>
                <Card style={{ maxWidth: '600px', margin: 'auto' }}>
                  <CardBody className="d-flex justify-content-between">
                    <table className="table">
                      <tbody>
                        {this.state.subscribedBots?.map(bot => {
                          var bot_expired = Object.prototype.hasOwnProperty.call(bot, 'subscription_status') && !bot.subscription_status;
                          
                          return (

                            <tr key={bot._id}>
                              <td>{bot.name}</td>
                              {
                                bot_expired ?
                                  <td className="error">{this.props.t("Expired on")} {new Date(bot.subscription_expiry)?.toLocaleString()}</td>
                                  :
                                  <td >{this.props.t("Expires on")} {new Date(bot.subscription_expiry)?.toLocaleString()}</td>

                              }
                              <td>
                                <span>{this.props.t("Auto Renew")} </span>
                                <span style={{ position: "relative", top: 4 }}>

                                  <Switch height={18} width={40} onChange={(value) => { this.toggleAutoRenew(bot, value) }} checked={bot?.auto_renew ? bot.auto_renew : false} />
                                </span>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </CardBody>
                </Card>
                <div style={{ height: '2rem' }}></div>
              </React.Fragment>
            ) : ''}

            <Row className="mt-5 justify-content-center">
              {plans?.map(plan => (
                <CardPricing
                  key={plan._id}
                  plan={plan}
                  selectedPlanID={planID}
                  history={this.props.history}
                  style={plan.style}
                  numPlans={plans?.length}
                  pricingInfo={this.props.pricingInfo}
                  monthlyPrice={plans?.find(plan => plan._id == '611226cd7b9fa3f2c999b12d')?.price}
                />
              ))}
            </Row>

            <SubscriptionHistory
              invoices={this.props.invoices}
              plans={this.props.plans}
            />
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Subscription.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object,
  user: PropTypes.any,
  plans: PropTypes.any,
  invoices: PropTypes.any,
  bots: PropTypes.any,
  pricingInfo: PropTypes.any,
  error: PropTypes.any,
  onGetPlans: PropTypes.func,
  onGetBots: PropTypes.func,
  onGetSubscriptionHistory: PropTypes.func,
  onAutoRenewBot: PropTypes.func,
}

const mapStateToProps = state => ({
  user: state.auth?.user,
  plans: state.user?.plans,
  invoices: state.user?.invoices,
  bots: state.bots?.bots,
  pricingInfo: state.user?.pricingInfo,
  error: state.error
})

const mapDispatchToProps = dispatch => ({
  onGetPlans: (onSuccess, onError) => dispatch(getPlans(onSuccess, onError)),
  onGetBots: (onSuccess, onError) => dispatch(getBots(null, onSuccess, onError)),
  onGetSubscriptionHistory: (onSuccess, onError) => dispatch(getSubscriptionHistory(onSuccess, onError)),
  onAutoRenewBot: (bot, onSuccess, onError) => dispatch(autoRenewBot(bot, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Subscription)))
