import React, { Component } from "react"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Col,
  Card,
  CardBody,
  Container,
  Row,
  Media,
  Button,
  Label,
  Input,
  Modal
} from "reactstrap"
import PropTypes from 'prop-types'

import Breadcrumbs from "navigation/Breadcrumb"

import CardPricing from "./card-pricing"

import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import plans from './plans'

import {
  initializeBot,
  getPlans,
  verifyPassword
} from "store/actions"



class Initialize extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      success: null,
      error: null,

      confirmModalVisible: false,

      password: '',
      selectedBot: null,
    }

    this.toggleConfirmModal = this.toggleConfirmModal.bind(this)

    this.redirectToBots = this.redirectToBots.bind(this)

    this.submitPayment = this.submitPayment.bind(this)

    this.onSubmitSuccess = this.onSubmitSuccess.bind(this)
    this.onSubmitError = this.onSubmitError.bind(this)

    this.selectBot = this.selectBot.bind(this)
  }

  componentDidMount() {
    if (!this.props.plans) {
      this.props.onGetPlans()
    }
  }


  redirectToBots() {
    this.props.history.push('/create-bot')
  }

  submitPayment() {
    this.setState({ loading: true })

    let id = this.props.match.params.id
    let params = {
      bot_id: this.state.selectedBot?._id,
      password: this.state.password,
      coin_type: 'PDT'
    }
    this.props.onInitialize(params, this.onSubmitSuccess, this.onSubmitError)
  }

  onSubmitSuccess(response) {
    this.setState({
      success: response?.msg,
      confirmModalVisible: false,
      loading: false
    })

    let id = this.props.match.params.id
    setTimeout(() => {
      this.props.history.push('/payment-success/' + id)
    }, 1000)
  }

  onSubmitError(response) {
    this.setState({
      error: response?.data?.msg,
      confirmModalVisible: false,
      loading: false
    })
  }

  selectBot(bot) {
    this.setState({ selectedBot: bot })
  }


  toggleConfirmModal() {
    this.setState({ confirmModalVisible: !this.state.confirmModalVisible })
  }

  confirmTransferModal() {
    return (
      <Modal
        isOpen={this.state.confirmModalVisible}
        toggle={this.toggleConfirmModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">{this.props.t("Purchase Initialization")}</h5>
          <button
            type="button"
            onClick={this.toggleConfirmModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <p>{this.props.t("Purchase this plan?")}</p>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.toggleConfirmModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {this.props.t("Close")}
            </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.submitPayment}
          >
            {this.state.loading ? (
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
            ) : this.props.t("Purchase")}
            </button>
        </div>
      </Modal>
    )
  }

  render() {
    let id = this.props.match.params.id
    let selectedPlan = this.props.plans.find(plan => plan._id == id)
    const { selectedBot } = this.state
    let selectedBotHasPDT = selectedBot?.pairs?.join(',')?.includes('PDT')
    let uninitializedBots = this.props.bots?.filter(bot => bot.is_initialised == false)

    return (
      <React.Fragment>
        <div className="page-content">
        <MetaTags>
            <title>{this.props.t("Atlantis | Payment")}</title>
          </MetaTags>
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title={this.props.t("Subscription")}
              breadcrumbItem={this.props.t("Payment")}
              parent="/subscription"
              to="/initialize"
            />

            {this.confirmTransferModal()}

            <Row className="justify-content-center">
              <Col lg={6}>
                <div className="text-center mb-5">
                  <h4>{!selectedBot ? this.props.t("Initialize Bot") : this.props.t("Confirmation & Payment")}</h4>
                  <p className="text-muted"></p>
                </div>
              </Col>
            </Row>

            {!uninitializedBots?.length && (
              <Card>
                <CardBody>
                  <p className="text-muted text-center">{this.props.t("You have no uninitialized bots.")}</p>
                  <Button type="button" color="success"
                    className="d-block m-auto"
                    onClick={this.redirectToBots}
                  >
                    {this.props.t("Create A Bot")}
                  </Button>
                </CardBody>
              </Card>
            )}

            {!selectedBot ? (
              <React.Fragment>
                {uninitializedBots?.map(bot => {
                  let hasPDT = bot.pairs?.join(',')?.includes('PDT')
                  return (
                    <Card key={bot._id}>
                      <CardBody className="d-flex align-items-center justify-content-between">
                        <p className="mb-0 fw-bold">{bot.name}</p>
                        <p className="mb-0 text-info">{this.props.t("Created on")} {new Date(bot.created_date)?.toLocaleString()}</p>
                        {hasPDT ? (
                          <div className="d-flex align-items-center">
                            <i className="bx bx-check-circle font-size-24 text-success" />
                            <p className="mb-0 ms-2 text-info">{this.props.t("Trading PDT")}</p>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center">
                            <p className="mb-0 ms-2 text-muted">{this.props.t("Not Trading PDT")}</p>
                          </div>
                        )}
                        <Button type="button" color="success"
                          onClick={() => this.selectBot(bot)}
                        >{this.props.t("Select")}</Button>
                      </CardBody>
                    </Card>
                  )
                })}
              </React.Fragment>

            ) : (

              <React.Fragment>
                <Col xl="4" md="6" className="mx-auto">
                  <Card className="plan-box">
                    <CardBody className="p-4">
                      <div className="mb-1 d-flex-column text-center">
                        <p className="text-muted mb-1">{this.props.t("Initialize Selected Bot")}</p>
                        <h5>{selectedBot?.name}</h5>
                      </div>

                      {selectedBotHasPDT ? (
                        <React.Fragment>
                          <div className="mt-4 d-flex-column text-center">
                            <p className="mb-1">Original Price</p>
                            <h5>{this.props.pricingInfo?.initial_fee?.toLocaleString() + ' PDT'}</h5>
                          </div>
                          <p className="text-success text-center mt-4 mb-0">{this.props.t("Bot Is Trading PDT (-50%)")}</p>
                          <div className="mt-4 d-flex-column text-center">
                            <p className="text-primary mb-1">Total</p>
                            <h3 className="text-primary">{(this.props.pricingInfo?.initial_fee / 2)?.toLocaleString() + ' PDT'}</h3>
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <p className="text-danger text-center mt-4 mb-0">{this.props.t("Bot Not Trading PDT")}</p>
                          <div className="mt-4 d-flex-column text-center">
                            <p className="mb-1">{this.props.t("Price")}</p>
                            <h5>{this.props.pricingInfo?.initial_fee?.toLocaleString() + ' PDT'}</h5>
                          </div>
                        </React.Fragment>
                      )}

                      <div className="plan-features">
                        {selectedPlan?.features?.map((feature, key) => (
                          <p key={"_feature_" + key}>
                            <i className="bx bx-checkbox-square text-primary me-2"/>{" "}
                            {feature.title}
                          </p>
                        ))}
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Card className="mx-auto" style={{ maxWidth: '480px' }}>
                  <CardBody className="p-4">
                    <div className="mb-3">
                      <Label>{this.props.t("Password")}</Label>
                      <Input type="password" className="form-control"
                        onChange={(e) => this.setState({ password: e.target.value })} />
                    </div>

                    {this.state.success && (
                      <Alert color="success" role="alert">
                        {this.state.success}
                      </Alert>
                    )}

                    {this.state.error && (
                      <Alert color="danger" role="alert">
                        {this.state.error}
                      </Alert>
                    )}
                  </CardBody>
                </Card>

                <div className="text-center mt-4">
                  <Button type="button" color="success"
                    onClick={this.toggleConfirmModal}
                    disabled={
                      !this.state.password
                    }
                  >
                    {this.state.loading ? (
                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                    ) : this.props.t("Submit")}
                  </Button>
                </div>
              </React.Fragment>
            )}

          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Initialize.propTypes = {
  t: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.object,
  user: PropTypes.any,
  plans: PropTypes.any,
  pricingInfo: PropTypes.any,
  bots: PropTypes.any,
  error: PropTypes.any,
  onInitialize: PropTypes.func,
  onGetPlans: PropTypes.func,
  onVerifyPassword: PropTypes.func,
}

const mapStateToProps = state => ({
  user: state.auth.user,
  plans: state.user?.plans,
  pricingInfo: state.user?.pricingInfo,
  bots: state.bots?.bots,
  error: state.error
})

const mapDispatchToProps = dispatch => ({
  onInitialize: (params, onSuccess, onError) => dispatch(initializeBot(params, onSuccess,onError)),
  onGetPlans: (onSuccess, onError) => dispatch(getPlans(onSuccess, onError)),
  onVerifyPassword: (password, onSuccess, onError) => dispatch(verifyPassword(password, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Initialize)))
