import { call, put, takeEvery } from "redux-saga/effects"

import {
  getMeSuccess,
  registerSuccess,
  registerFail,
  getMeFail,
  loginSuccess,
  loginFail,
  login2faSuccess,
  verify,
  useIP
} from "./actions"

import { get, post, setToken } from "helpers/api_helper"


function* getMe({ onSuccess, onError }) {
  try {
    const response = yield call(get, 'users/me')
    if (onSuccess) onSuccess(response)
    yield put(getMeSuccess(response))
  } catch (error) {
    if (onError) onError(error?.response);
    yield put(getMeFail(error?.response))
  }
}

function* register({ payload: userData, onSuccess, onError }) {
  try {
    const response = yield call(post, 'users', userData)
    onSuccess(response)
    yield put(registerSuccess(response.data))
  } catch (error) {
    onError(error.response);
    yield put(registerFail(error.response))
  }
}


function* login({ payload: auth, onSuccess, onError }) {
  try {
    // setIP(auth.ip_address)
    const response = yield call(post, 'users/auth', auth)
    if (response?.status == 'success') onSuccess(response)
    // yield put(useIP(auth.ip))
  } catch (error) {
    if (onError) onError(error?.response)
  }
}


function* login2fa({ payload: auth, onSuccess, onError }) {
  try {
    // setIP(auth.ip_address)
    const response = yield call(post, 'users/verify2faLogin', auth)
    if (response?.status == 'success') onSuccess(response?.data)
    // yield put(useIP(auth.ip))
  } catch (error) {
    if (onError) onError(error?.response)
  }
}


function* verifyPassword({ payload: password, onSuccess, onError }) {
  try {
    const response = yield call(post, 'users/verifyPassword', password)
    if (onSuccess) onSuccess(response)
  } catch (error) {
    if (onError) onError(error?.response)
  }
}


function* verifyIP({ payload: code, onSuccess, onError }) {
  try {
    const response = yield call(post, 'users/verifyLoginActivity', { validateKey: code })
    if (onSuccess) onSuccess(response)
  } catch (error) {
    if (onError) onError(error?.response)
  }
}


function* onVerify({ payload: code, onSuccess, onError }) {
  try {
    const response = yield call(get, 'users/act/' + code)
    if (onSuccess) onSuccess(response?.data)
  } catch (error) {
    if (onError) onError(error?.response)
  }
}


function* logout({ payload: page }) {
  try {
    yield console.log('');
    // yield put(logout(response.data))
  } catch (error) {
    console.error(error)
  }
}


function* botsSaga() {
  yield takeEvery('GET_ME', getMe)
  yield takeEvery('LOGIN', login)
  yield takeEvery('LOGIN_2FA', login2fa)
  yield takeEvery('VERIFY_PASSWORD', verifyPassword)
  yield takeEvery('VERIFY_IP', verifyIP)
  yield takeEvery('VERIFY', onVerify)
  yield takeEvery('LOGOUT', logout)
  yield takeEvery('REGISTER', register)
}

export default botsSaga
