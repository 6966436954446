import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Col, Row, Card, CardBody, Media, Button } from "reactstrap"
import { withTranslation } from "react-i18next"

class CardPricing extends Component {

  render() {
    let isCurrentPlan = this.props.selectedPlanID == this.props.plan._id
    let isAnnualPlan = this.props.plan?._id == '611226cd7b9fa3f2c999b131'

    return (
      <Col xl="4" md="6">
        <Card className="plan-box">
          <CardBody className="p-4">

            <Media>
              <Media body>
                <h5 className={isCurrentPlan ? 'text-success' : ''}>{this.props.plan?.name}</h5>
                <p className="text-muted">{this.props.plan?.description}</p>
              </Media>
              <div className="ms-3">
                {isCurrentPlan ? (
                  <i className={"bx bx-check h1 text-success"} />
                ) : (
                  <i className={"bx " + this.props.plan.icon + " h1 text-primary"} />
                )}
              </div>
            </Media>

            <div className={isAnnualPlan ? "pt-3 pb-5" : "pt-5 mt-3 pb-5"}>
              {isAnnualPlan && (
                <React.Fragment>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-1 text-muted">{this.props.t("Price of 12 Months")}</p>
                    <p className="mb-1 text-muted">{(this.props.monthlyPrice * 12)?.toLocaleString() + ' PDT'}</p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-1 text-info"></p>
                    <p className="mb-1 text-info">{(this.props.plan?.price - (this.props.monthlyPrice * 12))?.toLocaleString() + ' PDT'}</p>
                  </div>
                </React.Fragment>
              )}
              {/*
              <div className="d-flex align-items-center justify-content-between">
                <p className="mb-2">Monthly Subscription Fee</p>
                <p className="mb-2">{this.props.plan?.price?.toLocaleString() + ' PDT'}</p>
              </div>
              */}

              <div className="mt-3 d-flex-column text-center">
                <p className="mb-1">{this.props.t("Total")}</p>
                <h5>{this.props.plan?.price?.toLocaleString() + ' PDT'}</h5>
              </div>

              <div className="mt-4 d-flex-column text-center">
                <p className="text-primary mb-1">{this.props.t("With a Bot Trading PDT (-50%)")}</p>
                <h3 className="text-primary">{(this.props.plan?.price / 2)?.toLocaleString() + ' PDT'}</h3>
              </div>
            </div>

            <div className="text-center">
              <Button
                onClick={() => this.props.history.push('payment/' + this.props.plan?._id)}
                color="primary"
                className="btn btn-primary btn-sm"
              >
                {this.props.t("Choose This Plan")}
              </Button>
            </div>

            <div className="plan-features mt-5">
              {this.props.plan?.features?.map((feature, key) => (
                <p key={"_feature_" + key}>
                  <i className="bx bx-checkbox-square text-primary me-2"/>{" "}
                  {feature.title}
                </p>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    )
  }
}

CardPricing.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  plan: PropTypes.object,
  selectedPlanID: PropTypes.string,
  numPlans: PropTypes.number,
  pricingInfo: PropTypes.any,
  monthlyPrice: PropTypes.number,
}

export default withTranslation()(CardPricing)
