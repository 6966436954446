
const INIT_STATE = {
  balance: {},
  prices: {},
  plans: [],
  pricingInfo: {
    initial_fee: 75000
  },
  referrals: [],
  referralPayout: [],
  walletHistory: [],
  invoices: [],
}

const User = (state = INIT_STATE, action) => {
  switch (action.type) {

    case 'GET_BALANCE_SUCCESS':
      return {
        ...state,
        balance: action.payload,
      }

    case 'GET_BALANCE_FAIL':
      return {
        ...state,
        error: action.payload,
      }

      

    case 'GET_PRICES_SUCCESS':
      return {
        ...state,
        prices: action.payload,
      }



    case 'GET_PLANS_SUCCESS':
      return {
        ...state,
        plans: action.payload
      }



    case 'GET_SUBSCRIPTION_HISTORY_SUCCESS':
      return {
        ...state,
        invoices: action.payload
      }



    case 'GET_TRANSACTION_HISTORY_SUCCESS':
      return {
        ...state,
        walletHistory: action.payload
      }



    case 'GET_REFERRALS_SUCCESS':
      return {
        ...state,
        referrals: action.payload?.data,
        referralPayout: action.payload?.profits,
      }
    case 'GET_REFERRALS_WITHDRAWALS_SUCCESS':
      return {
        ...state,
        referralWithdrawals: action.payload?.data,
      }
    case 'GET_REFERRALS_EARNINGS_SUCCESS':
      return {
        ...state,
        referralEarnings: action.payload?.data,
      }

      case 'AUTO_RENEW_BOT_SUCCESS':
      return {
        ...state,
        bots: state.bots.filter(bot => bot._id != action.payload._id)
      }

    case 'AUTO_RENEW_BOT_FAIL':
      return {
        ...state,
        error: action.payload,
      }



    default:
      return state
  }
}

export default User
