import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { isEmpty, size } from "lodash"
import { Link } from "react-router-dom"
import {
  Alert,
  Button,
  Badge,
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Label,
  Input,
  Modal,
} from "reactstrap"
import MetaTags from 'react-meta-tags'

import Breadcrumbs from "navigation/Breadcrumb"

import ReferralWithdrawals from './referralWithdrawals';
import ReferralEarnings from './referralEarnings';

import {
  getReferrals,
  getReferralWithdrawals,
  getReferralEarnings,
  withdrawReferralPayout,
  verifyPassword,
  verify2fa,
} from 'store/actions'



class Referrals extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      loadingPayout: false,
      referralWithdrawalsLoading: false,
      referralEarningsLoading: false,
      successPayout: null,
      errorPayout: null,
      success: null,
      error: null,
      errorPassword: null,

      passwordModalVisible: false,
      verify2faModalVisible: false,
      confirmModalVisible: false,
      withdrawAmount: null,
      password: '',
      code2fa: null,
      withdrawComplete: false,

      referralLink: 'https://atlantisgold.trade/register/',

      showReferrals: true,
      open: {},
    }

    this.onGetReferralsSuccess = this.onGetReferralsSuccess.bind(this)
    this.onGetReferralsError = this.onGetReferralsError.bind(this)

    this.onGetReferralWithdrawalsSuccess = this.onGetReferralWithdrawalsSuccess.bind(this)
    this.onGetReferralWithdrawalsError = this.onGetReferralWithdrawalsError.bind(this)

    this.onGetReferralEarningsSuccess = this.onGetReferralEarningsSuccess.bind(this)
    this.onGetReferralEarningsError = this.onGetReferralEarningsError.bind(this)

    this.referralChild = this.referralChild.bind(this)
    this.toggleOpen = this.toggleOpen.bind(this)
    this.onClickCopy = this.onClickCopy.bind(this)

    this.togglePasswordModal = this.togglePasswordModal.bind(this)
    this.toggle2faModal = this.toggle2faModal.bind(this)

    this.verifyPassword = this.verifyPassword.bind(this)
    this.onVerifyPasswordSuccess = this.onVerifyPasswordSuccess.bind(this)
    this.onVerifyPasswordError = this.onVerifyPasswordError.bind(this)
    this.confirmWithdraw = this.confirmWithdraw.bind(this)
    this.verify2FA = this.verify2FA.bind(this)
    this.onVerify2FAError = this.onVerify2FAError.bind(this)

    this.withdrawPayout = this.withdrawPayout.bind(this)
    this.onWithdrawPayoutSuccess = this.onWithdrawPayoutSuccess.bind(this)
    this.onWithdrawPayoutError = this.onWithdrawPayoutError.bind(this)
  }

  componentDidMount() {
    this.setState({ loading: true, referralWithdrawalsLoading: true, referralEarningsLoading: true })
    this.props.onGetReferrals(this.onGetReferralsSuccess, this.onGetReferralsError)
    this.props.onGetReferralWithdrawals(this.onGetReferralWithdrawalsSuccess, this.onGetReferralWithdrawalsError)
    this.props.onGetReferralEarnings(this.onGetReferralEarningsSuccess, this.onGetReferralEarningsError)
  }

  onGetReferralsSuccess(response) {
    this.setState({ loading: false })
  }

  onGetReferralsError(response) {
    this.setState({
      loading: false,
      error: this.props.t("Problem fetching referral data.")
    })
  }
  onGetReferralWithdrawalsSuccess(response) {
    this.setState({ referralWithdrawalsLoading: false })
  }

  onGetReferralWithdrawalsError(response) {
    this.setState({
      referralWithdrawalsLoading: false,
      error: this.props.t("Problem fetching referral data.")
    })
  }
  onGetReferralEarningsSuccess(response) {
    this.setState({ referralEarningsLoading: false })
  }

  onGetReferralEarningsError(response) {
    this.setState({
      referralEarningsLoading: false,
      error: this.props.t("Problem fetching referral data.")
    })
  }

  verifyPassword() {
    this.setState({ loadingPayout: true })
    let params = { password: this.state.password }
    this.props.onVerifyPassword(params, this.onVerifyPasswordSuccess, this.onVerifyPasswordError)
    // this.onVerifyPasswordSuccess(null)
  }

  onVerifyPasswordSuccess(response) {
    this.setState({
      loadingPayout: false,
      passwordModalVisible: false,
      confirmModalVisible: true,
    })
  }

  onVerifyPasswordError(response) {
    this.setState({
      loadingPayout: false,
      error2fa: response?.data?.msg
    });
  }

  confirmWithdraw() {
    let has2fa = this.props.user?.two_fa_enable
    if (has2fa) {
      this.setState({
        loadingPayout: false,
        passwordModalVisible: false,
        confirmModalVisible: false,
        verify2faModalVisible: true,
      })
    }
    else this.withdrawPayout()
  }

  verify2FA() {
    this.props.onVerify2FA(this.state.code2fa, this.withdrawPayout, this.onVerify2FAError)
  }

  onVerify2FAError(response) {
    this.setState({
      loadingPayout: false,
      error2fa: response?.data?.msg
    });
  }

  withdrawPayout() {
    this.setState({
      passwordModalVisible: false,
      confirmModalVisible: false,
      verify2faModalVisible: false,
      loadingPayout: true
    })

    let params = {
      coin_type: 'PDT',
      amount: this.state.withdrawAmount,
    }
    this.props.onWithdrawPayout(params, this.onWithdrawPayoutSuccess, this.onWithdrawPayoutError)
    // setTimeout(() => {
    //   this.onWithdrawPayoutSuccess(null)
    // }, 1000)
  }

  onWithdrawPayoutSuccess(response) {
    this.setState({
      loadingPayout: false,
      successPayout: this.props.t("Transfer Successful. Check your email to complete your withdrawal."),
      withdrawComplete: true
    })

    setTimeout(() => {
      window.location?.reload()
    }, 2000)
  }

  onWithdrawPayoutError(response) {
    this.setState({
      loadingPayout: false,
      errorPayout: response?.data?.msg
    })
  }


  toggleOpen(id) {
    let open = this.state.open

    if (open[id]) {
      delete open[id]
    }
    else {
      open[id] = true
    }

    this.setState({ open })
  }

  onClickCopy() {
    let input = document.getElementById('link');
    if (!input) {
      this.setState({ error: this.props.t("Problem copying to clipboard. Please copy manually.") })
      return;
    }
    input.focus();
    input.select();
    document.execCommand('copy', false);
    input.blur();
    window.getSelection().removeAllRanges();

    this.setState({ success: this.props.t("Copied to clipboard.") })
  }

  togglePasswordModal() {
    this.setState({ passwordModalVisible: !this.state.passwordModalVisible })
  }
  toggleConfirmModal() {
    this.setState({ confirmModalVisible: !this.state.confirmModalVisible })
  }
  toggle2faModal() {
    this.setState({ verify2faModalVisible: !this.state.verify2faModalVisible })
  }


  passwordModal() {
    return (
      <Modal
        isOpen={this.state.passwordModalVisible}
        toggle={this.togglePasswordModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">{this.props.t("Withdraw Payout")}</h5>
          <button
            type="button"
            onClick={this.togglePasswordModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">

          <p>{this.props.t("Enter Amount to Withdraw")}</p>

          <Input
            type="number"
            className="form-control"
            onInput={(e) => this.setState({ withdrawAmount: e.target.value })}
          />

          <p className="mt-4">{this.props.t("Confirm Password")}</p>

          <Input
            type="password"
            className="form-control"
            onInput={(e) => this.setState({ password: e.target.value })}
          />

          {this.state.error2fa && (
            <Alert color="danger" role="alert" className="mt-3">
              {this.state.error2fa}
            </Alert>
          )}

        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.togglePasswordModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {this.props.t("Close")}
            </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.verifyPassword}
            disabled={!this.state.password?.trim() || !this.state.withdrawAmount}
          >
            {this.state.loadingPayout ? (
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
            ) : this.props.t("Submit")}
          </button>
        </div>
      </Modal>
    )
  }

  confirmWithdrawModal() {
    return (
      <Modal
        isOpen={this.state.confirmModalVisible}
        toggle={this.toggleConfirmModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">{this.props.t("Confirm Withdraw")}</h5>
          <button
            type="button"
            onClick={this.toggleConfirmModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <p>{this.props.t("Withdraw")} <span className="text-primary fw-bold">{this.state.withdrawAmount}</span> {this.props.t("PDT from Referral Payout to your wallet?")}</p>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.toggleConfirmModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {this.props.t("Close")}
            </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.confirmWithdraw}
          >
            {this.props.t("Confirm")}
            </button>
        </div>
      </Modal>
    )
  }

  twoFAModal() {
    return (
      <Modal
        isOpen={this.state.verify2faModalVisible}
        toggle={this.toggle2faModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">{this.props.t("Two Factor Authentication")}</h5>
          <button
            type="button"
            onClick={this.toggle2faModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">

          <p>{this.props.t("Please enter your Google Authenticator code below to log in.")}</p>

          <Input
            type="number"
            className="form-control"
            onInput={(e) => this.setState({ code2fa: e.target.value })}
          />

          {this.state.error2fa && (
            <Alert color="danger" role="alert" className="mt-3">
              {this.state.error2fa}
            </Alert>
          )}

        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.toggle2faModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {this.props.t("Close")}
            </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={this.verify2FA}
            disabled={!this.state.code2fa}
          >
            {this.state.loadingPayout ? (
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
            ) : this.props.t("Submit")}
          </button>
        </div>
      </Modal>
    )
  }

  referralChild(referral) {
    return (
      <React.Fragment key={referral._id}>
        <div className="d-flex align-items-center hover-cursor">
          {referral.child?.length ? (
            <i className={`mdi mdi-chevron-right font-size-16 me-1`} style={{
              transition: 'transform 0.3s',
              transform: this.state.open[referral.id] ? 'rotate(90deg)' : 'rotate(0deg)'
            }}></i>
          ) : (
              <div style={{ marginLeft: '20px' }}></div>
            )}
          <div
            className="d-flex mx-1 my-2 px-3 py-2 bg-success bg-soft"
            style={{ borderRadius: '0.25rem' }}
            key={referral.id}
            onClick={() => this.toggleOpen(referral.id)}
          >
            <div className="d-flex-column align-items-center justify-content-center">
              {/* <p className="mb-0">{referral.name}</p> */}
              <p className="mb-0 text-muted font-size-11">{referral.email}</p>
            </div>
            <p className="mb-0 ms-4 text-info">Tier {referral.refer_level}</p>
            {referral.amount && (<p className="mb-0 ms-4 text-success">{referral.amount + ' PDT'}</p>)}
          </div>
        </div>
        <div className="ms-4">
          {(referral.child?.length && this.state.open[referral.id]) ?
            referral.child.map(child => this.referralChild(child))
            : ''}
        </div>
      </React.Fragment>
    )

  }

  render() {
    let referralCode = this.props.user?.referral_code
    const { referrals } = this.props

    let isEligibleForPayout = this.props.user?.address?.confirmed_balance >= 500000

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Atlantis | Referrals")}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title={this.props.t("Referrals")} />

            {this.passwordModal()}
            {this.confirmWithdrawModal()}
            {this.twoFAModal()}

            <h5 className="mb-3">{this.props.t("Referrals")}</h5>

            <Card>
              <CardBody>
                <Label>{this.props.t("Referral Link")}</Label>
                <div className="input-group mb-3">
                  <Input type="text" id="link" className="form-control" readOnly
                    value={referralCode ? (this.state.referralLink + referralCode) : this.props.t("No referral code found")} />
                  <button type="button" className="btn btn-primary"
                    onClick={this.onClickCopy}
                  >{this.props.t("Copy")}</button>
                </div>
                {this.state.success && (
                  <Alert color="success" role="alert">
                    {this.state.success}
                  </Alert>
                )}

                {this.state.error && (
                  <Alert color="danger" role="alert">
                    {this.state.error}
                  </Alert>
                )}
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Row className="align-items-center">
                  <Col sm="4" className="my-3 text-center">
                    <p className="mb-2">{this.props.t("Total Amount Earned")}</p>
                    <h6 className="mb-0">{this.props.referralPayout?.available_profits?.toLocaleString()} PDT</h6>
                  </Col>
                  <Col sm="4" className="my-3 text-center">
                    {this.props.user ? (
                      <React.Fragment>
                        <div className="d-flex justify-content-center align-items-center">
                          <i className={`bx font-size-24 ${isEligibleForPayout ? 'text-success bx-check-circle' : 'text-danger bx-x-circle'}`} />
                          <h6 className={`ms-2 mb-0 ${isEligibleForPayout ? 'text-success' : 'text-danger'}`}>{
                            isEligibleForPayout ? this.props.t("Eligible For Payout") : this.props.t("Not Eligible For Payout")
                          }</h6>
                        </div>
                        <p className="mb-0 mt-1 text-muted font-size-11">{
                          isEligibleForPayout ? this.props.t("Wallet has at least 500,000 PDT") : this.props.t("Wallet does not have 500,000 PDT")
                        }</p>
                        {!isEligibleForPayout && (
                          <Link to="/transfer/deposit" className="mt-2 btn btn-success btn-sm w-md">
                            {this.props.t("Add More PDT")}
                          </Link>
                        )}
                      </React.Fragment>
                    ) : (
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                      )}
                  </Col>
                  <Col sm="4" className="my-3 text-center">
                    <Button className="btn bg-primary btn-sm w-md"
                      onClick={this.togglePasswordModal}
                      disabled={!this.props.referralPayout?.available_profits || this.state.withdrawComplete}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <i className="font-size-24 my-1 me-2 bx bx-send" />
                        {this.state.loadingPayout ? (
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                        ) : (
                            <p className="mb-0">{this.props.t("Withdraw Payout")}</p>
                          )}
                      </div>
                    </Button>
                  </Col>
                </Row>

                {this.state.successPayout && (
                  <Alert color="success" role="alert" className="mb-0 mt-3">
                    {this.state.successPayout}
                  </Alert>
                )}

                {this.state.errorPayout && (
                  <Alert color="danger" role="alert" className="mb-0 mt-3">
                    {this.state.errorPayout}
                  </Alert>
                )}
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <h4 className="card-title mb-4">{this.props.t("Referral Withdrawal History")}</h4>
                {this.state.referralWithdrawalsLoading ? (
                  <div className="d-block">
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    <span>{this.props.t("Loading...")}</span>
                  </div>
                ) : (
                    <ReferralWithdrawals
                      referralWithdrawals={this.props.referralWithdrawals}
                    />
                  )}
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <h4 className="card-title mb-4">{this.props.t("Referral Earnings History")}</h4>
                {this.state.referralEarningsLoading ? (
                  <div className="d-block">
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    <span>{this.props.t("Loading...")}</span>
                  </div>
                ) : (
                    <ReferralEarnings
                      referralEarnings={this.props.referralEarnings}
                    />
                  )}
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <Label className="mb-3">{this.props.t("Referral Tree")}</Label>

                {this.state.loading ? (
                  <div className="d-block">
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                    <span>{this.props.t("Loading...")}</span>
                  </div>
                ) : (
                    <React.Fragment>
                      <div className="d-flex align-items-center hover-cursor">
                        <i className={`mdi mdi-chevron-right font-size-16 me-1`} style={{
                          transition: 'transform 0.3s',
                          transform: this.state.showReferrals ? 'rotate(90deg)' : 'rotate(0deg)'
                        }}></i>
                        <div
                          className="d-flex align-items-center mx-1 my-2 px-3 py-2 bg-success bg-soft"
                          style={{ borderRadius: '0.25rem' }}
                          onClick={() => this.setState({ showReferrals: !this.state.showReferrals })}
                        >
                          <div className="d-flex-column justify-content-center">
                            {/* <p className="mb-0">{this.props.user?.name}</p> */}
                            <p className="mb-0 text-muted font-size-11">{this.props.user?.email}</p>
                          </div>
                        </div>
                      </div>

                      <div className="ms-4">
                        {(this.state.showReferrals && referrals?.length) ?
                          referrals?.map(referral => this.referralChild(referral)) : ''}
                      </div>
                    </React.Fragment>
                  )}
              </CardBody>
            </Card>


            
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Referrals.propTypes = {
  t: PropTypes.any,
  user: PropTypes.object,
  referrals: PropTypes.any,
  referralPayout: PropTypes.any,
  onGetReferrals: PropTypes.func,
  onWithdrawPayout: PropTypes.func,
  onVerifyPassword: PropTypes.func,
  onVerify2FA: PropTypes.func,
  onGetReferralWithdrawals: PropTypes.func,
  referralWithdrawals: PropTypes.any,
  onGetReferralEarnings: PropTypes.func,
  referralEarnings: PropTypes.any,
}

const mapStateToProps = state => ({
  user: state.auth.user,
  referrals: state.user.referrals,
  referralPayout: state.user.referralPayout,
  referralWithdrawals: state.user?.referralWithdrawals,
  referralEarnings: state.user?.referralEarnings,
})

const mapDispatchToProps = dispatch => ({
  onGetReferrals: (onSuccess, onError) => dispatch(getReferrals(onSuccess, onError)),
  onGetReferralWithdrawals: (onSuccess, onError) => dispatch(getReferralWithdrawals(onSuccess, onError)),
  onGetReferralEarnings: (onSuccess, onError) => dispatch(getReferralEarnings(onSuccess, onError)),
  onWithdrawPayout: (params, onSuccess, onError) => dispatch(withdrawReferralPayout(params, onSuccess, onError)),
  onVerifyPassword: (password, onSuccess, onError) => dispatch(verifyPassword(password, onSuccess, onError)),
  onVerify2FA: (code, onSuccess, onError) => dispatch(verify2fa(code, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(Referrals)))
