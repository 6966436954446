import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
  Button,
  Badge,
  Container,
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  Label,
  Alert
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { isEmpty, size } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import { Link } from "react-router-dom"

import Breadcrumbs from "navigation/Breadcrumb"

import {
  getBalance,
  getPrices,
  getBots,
  getAPIs,
  createBot,
  startStopBot,
  deleteBot,
  deleteBotSuccess,
} from "store/actions"

import { get, post } from "helpers/api_helper"



const icons = {
  BTC: 'mdi-bitcoin',
  ETH: 'mdi-ethereum',
  XRP: 'mdi-bitcoin',
  USDT: 'mdi-currency-usd',
  BUSD: 'mdi-currency-usd',
  USD: 'mdi-currency-usd',
  JPY: 'mdi-currency-jpy',
}


// --------------------
// MARKET DATA

// const ccxt = require('ccxt');
// const binance = new ccxt.binance();
// let markets;
// let prices;

// --------------------
// COLORS

const green = '#5fcb80';
const red = '#f0616e';

// --------------------

import { BOT_DIFFERENCE } from './bot_settings'



class BotList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      success: null,
      loadingBalance: false,
      loadingBotChange: false,

      updateModalVisible: false,
      updatingBot: null,

      assetsUSDT: null,
      assetsAverage: null,

      coins: [],
      botColumns: [],

      prices: null,
      insufficientBalances: null
    }

    this.getBotListColumn = this.getBotListColumn.bind(this)
    this.fetchPrices = this.fetchPrices.bind(this)
    this.fetchPricesSuccess = this.fetchPricesSuccess.bind(this)
    this.fetchPricesError = this.fetchPricesError.bind(this)

    this.redirectToSubscription = this.redirectToSubscription.bind(this)

    this.onToggleUpdateBot = this.onToggleUpdateBot.bind(this)
    this.toggleUpdateModal = this.toggleUpdateModal.bind(this)
    this.calculateAssetsAverage = this.calculateAssetsAverage.bind(this)
    this.coinDifference = this.coinDifference.bind(this)
    this.displayCoinDifference = this.displayCoinDifference.bind(this)
    this.isAssetSufficient = this.isAssetSufficient.bind(this)
    this.onConfirmStartStop = this.onConfirmStartStop.bind(this)
    this.onStartStopSuccess = this.onStartStopSuccess.bind(this)
    this.onStartStopError = this.onStartStopError.bind(this)

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this)
    this.onToggleDeleteBot = this.onToggleDeleteBot.bind(this)
    this.onConfirmDeleteBot = this.onConfirmDeleteBot.bind(this)
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this)
    this.onDeleteError = this.onDeleteError.bind(this)

    this.onGetBalanceSuccess = this.onGetBalanceSuccess.bind(this)
    this.onGetBalanceError = this.onGetBalanceError.bind(this)
  }

  // async loadMarkets() {
  //   markets = await binance.load_markets();
  // }

  getBotListColumn(){
    return [
      {
        dataField: "name",
        text: this.props.t("Bot Name"),
        events: { onClick: this.onClickRow }
      },
      {
        dataField: "api_id",
        text: this.props.t("Exchange"),
        events: { onClick: this.onClickRow },
        formatter: (cellContent, row) => (
          // this.props.exchangeAPIs?.find(api => api._id == row.api_id)?.exchange_id?.name
          row.api_id?.exchange_id?.name
        )
      },
      {
        dataField: "delta",
        text: this.props.t("Delta"),
        events: { onClick: this.onClickRow },
        formatter: (cellContent, row) => (
          (row.delta * 100) + '%'
        )
      },
      {
        dataField: "bp",
        text: this.props.t("Basis Points"),
        events: { onClick: this.onClickRow }
      },
      {
        dataField: "aip",
        text: this.props.t("Return"),
        events: { onClick: this.onClickRow }
      },
      {
        dataField: "updated_at",
        text: this.props.t("Start Date"),
        events: { onClick: this.onClickRow },
        formatter: (cellContent, row) => (
          row.updated_at ? (
            new Date(row.updated_at)?.toLocaleString()
          ) : (
              new Date(row.created_date)?.toLocaleString()
            )
        )
      },
      {
        dataField: "is_initialised",
        text: this.props.t("Initialized"),
        formatter: (cellContent, row) => (
          row.is_initialised ? (
            <i className="bx bx-check-circle font-size-24 text-success" />
          ) : (
              <div className="d-flex align-items-center">
                <i className="bx bx-x-circle font-size-24 text-danger" />
                <Button
                  type="button"
                  color="success"
                  style={{ marginLeft: '0.5rem' }}
                  onClick={this.redirectToSubscription}
                >
                {this.props.t("Initialize")}
              </Button>
              </div>
            )
        )
      },
      {
        dataField: "subscription_expiry",
        text: this.props.t("Subscribed"),
        formatter: (cellContent, row) => (
          !row.subscription_expiry || (Object.prototype.hasOwnProperty.call(row, "subscription_status") && !row.subscription_status) ? (
            <div className="d-flex align-items-center">
              <i className="bx bx-x-circle font-size-24 text-danger" />
              <Button
                type="button"
                color="success"
                style={{ marginLeft: '0.5rem' }}
                onClick={this.redirectToSubscription}
              >
                {this.props.t("Subscribe")}
              </Button>
            </div>
          ) : (
              <i className="bx bx-check-circle font-size-24 text-success" />
            )
        )
      },
      {
        dataField: "status",
        text: this.props.t("Status"),
        events: { onClick: this.onClickRow },
        formatter: (cellContent, row) => (
          row.status ? (
            <span className="text-success">{this.props.t("Going")}</span>
          ) : (
              <span className="text-danger">{this.props.t("Stopped")}</span>
            )
        )
      },
      {
        dataField: "",
        text: this.props.t("Actions"),
        formatter: (cellContent, row) => (
          <React.Fragment>
            {/* {console.log("cellContent", cellContent)}
            {console.log("row", row)} */}
            <Button
              type="button"
              color={row.status ? 'danger' : 'success'}
              onClick={() => this.onToggleUpdateBot(row)}
              disabled={!row.is_initialised || !row.subscription_expiry}
            >
              {row.status ? this.props.t("Stop") : this.props.t("Start")}
            </Button>

            {/* <Button
              type="button"
              color="secondary"
              style={{ marginLeft: '0.25rem' }}
              onClick={() => this.onToggleDeleteBot(row)}
            >
              Delete
            </Button> */}

            <Button
              type="button"
              color="secondary"
              style={{ marginLeft: '0.25rem' }}
              onClick={() => this.onClickEdit(row)}
            >
              {this.props.t("Edit")}
            </Button>

          </React.Fragment>
        )
      }
    ]
  }

  async fetchPrices(coinsArray) {
    // console.log('coinsArray', coinsArray);
    
    this.props.onGetPrices(coinsArray, this.fetchPricesSuccess, this.fetchPricesError)

    // let prices = await binance.fetchTickers(coinsArray);
    // this.setState({ prices })
  }

  fetchPricesSuccess() {
    this.calculateAssetsAverage()
    this.setState({ loadingBalance: false })
  }

  fetchPricesError() {
    this.calculateAssetsAverage()
    this.setState({ loadingBalance: false })
  }



  componentDidMount() {
    const { bots, onGetBots } = this.props
    // if (bots && !bots.length) {
    onGetBots({
      skip: 0,
      limit: 10
    })
    // }
    // this.props.onGetAPIs()

    // this.loadMarkets()
  }

  componentDidUpdate(prevProps) {
    // console.log("componentDidUpdate called")
    // if (this.props.)
    // this.setState({});
    // console.log("props ", this.props)
    // console.log("Subscribed ", this.props.t("Subscribed"))
    // let botListColumn = this.getBotListColumn();
    // this.setState({botColumns: botListColumn})
  }


  // UNSAFE_componentWillReceiveProps(){
  //   let botListColumn = this.getBotListColumn();
  //   this.setState({botColumns: botListColumn})
  // }

  onClickEdit = (row) => {
    this.props.history.push('/edit-bot/' + row._id);
  }
  onClickRow = (e, column, columnIndex, row, rowIndex) => {
    this.props.history.push('/bot-detail/' + row._id);
  }

  redirectToSubscription() {
    this.props.history.push('/subscription')
  }

  toggleUpdateModal() {
    this.setState(prevState => ({
      updateModalVisible: !prevState.updateModalVisible,
    }))
  }

  onToggleUpdateBot(bot) {
  
    if (!bot.status) {
    this.setState({
      loadingBalance: true,
      updatingBot: bot,
      insufficientBalances: null
    });

    this.props.onGetBalance(bot.api_id._id, this.onGetBalanceSuccess, this.onGetBalanceError)
  } else {
    this.setState({
      updatingBot: bot
    });
  }
    this.toggleUpdateModal();
  }

  onGetBalanceSuccess(response) {
    // console.log("ronGetBalanceSuccess esponse ", response);
    const { updatingBot } = this.state
    
    
    let coinNames = updatingBot.trade_coins;
    let data={}
    if (coinNames && coinNames.length) {
      let coins = []
      coinNames.forEach(coin => {
        if (coin.coin_type && coin.coin_type != 'USDT') {
          coins.push(coin.coin_type + '/USDT')
        }
        data={
          coins:coins,
          api_id:updatingBot.api_id._id
        }
      });
     
      this.setState({ coins })
      this.fetchPrices(data)
    }
    else {
      this.setState({
        loadingBalance: false,
        insufficientBalances: [response.data?.msg]
      })
    }

    // if (!prices) {
    //   setTimeout(() => {
    //     this.calculateAssetsAverage()
    //   }, 2000);
    // }
    // else {
    //   this.calculateAssetsAverage()
    // }
  }

  onGetBalanceError(response) {
    this.setState({
      loadingBalance: false,
      insufficientBalances: [response.data?.msg]
    })
  }

  calculateAssetsAverage() {
    const { prices } = this.props
    const coins = this.state.updatingBot?.trade_coins?.map(coin => coin.coin_type)
    const { balance } = this.props

    let error = '';
    if (!coins || !coins.length) {
      error += this.props.t("No coins.")+' '
    }
    if (!prices) {
      error += this.props.t("No prices.")+' '
    }
    if (!balance) {
      error += this.props.t("No balance.")+' '
    }

    if (error.trim()) {
      this.setState({ error })
      return;
    }
    else {
      this.setState({ error: null })
    }

    let assetsUSDT = {}
    coins.forEach(coin => {
      if (coin == 'USD' || coin == 'USDT') {
        assetsUSDT[coin] = balance[coin]
      }
      else {
        assetsUSDT[coin] = balance[coin] * prices[coin + '/USDT']?.price
      }
    });

    let values = Object.values(assetsUSDT)
    let total = values.reduce((x, i) => x + i);
    let assetsAverage = total / values.length;

    this.setState({ assetsUSDT, assetsAverage })

    this.calcBalanceErrors()
    // setTimeout(() => {
    // }, 100)
  }

  onConfirmStartStop() {
    this.setState({
      updateModalVisible: false,
      loadingBotChange: true
    });

    this.props.onStartStopBot(this.state.updatingBot, this.onStartStopSuccess, this.onStartStopError);
  }

  onStartStopSuccess(response) {
    let bot = this.props.bots.find(bot => bot._id == this.state.updatingBot._id);
    this.setState({
      success: this.props.t("Bot")+` "${bot.name}" ${bot.status ? this.props.t("stopped") : this.props.t("started")} `+this.props.t("successfully"),
      updatingBot: null,
      loadingBotChange: false
    })
  }

  onStartStopError(response) {
    let error = this.props.t("Connection Error")
    if (response) error = response?.data?.msg

    this.setState({
      error,
      loadingBotChange: false
    })
  }




  onToggleDeleteBot(bot) {
    this.setState({ updatingBot: bot });

    this.toggleDeleteModal();
  }

  toggleDeleteModal() {
    this.setState({ deleteModalVisible: !this.state.deleteModalVisible });
  }

  onConfirmDeleteBot() {
    this.props.onDeleteBot(this.state.updatingBot, this.onDeleteSuccess, this.onDeleteError)
    this.setState({ deleteModalVisible: false })
  }

  onDeleteSuccess(response) {
    this.setState({ success: this.props.t("Bot successfully deleted.") })
  }

  onDeleteError(response) {
    if (!response) {
      this.setState({ error: this.props.t("Connection Error") })
    }
    else {
      this.setState({ error: response.error })
    }
  }

  deleteBotModal() {
    return (
      <Modal
        isOpen={this.state.deleteModalVisible}
        toggle={this.toggleDeleteModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">{this.props.t("Delete Bot")}</h5>
          <button
            type="button"
            onClick={this.toggleDeleteModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <h5>{this.props.t("After deleting bot [botName] you will not get any refund for subscription?").replace("[botName]", this.state.updatingBot?.name)}</h5>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.toggleDeleteModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            {this.props.t("Close")}
            </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={this.onConfirmDeleteBot}
          >
            {this.props.t("Delete")}
            </button>
        </div>
      </Modal>
    )
  }



  // balanceRequired(coin) {
  //   const { updatingBot, prices } = this.state;
  //
  //   if (!prices || !prices[coin]) return '--';
  //
  //   let amount = updatingBot.amount || 2000;
  //   let perCoinAmount = amount / updatingBot.trade_coins.length;
  //
  //   let currentPrice = perCoinAmount / prices[coin].close;
  //
  //   return parseFloat(binance.amountToPrecision(coin, currentPrice));
  // }
  //
  // isBalanceSufficient(coin) {
  //   let coinName = coin.split('/')[0];
  //   if (!coinName) return '--';
  //   return this.props.balance?.[coinName] >= this.balanceRequired(coin);
  // }

  coinDifference(coin) {
    const { assetsUSDT, assetsAverage } = this.state

    let difference = assetsUSDT?.[coin] / assetsAverage;
    return difference - 1;
  }

  displayCoinDifference(coin) {
    let difference = this.coinDifference(coin) * 100;
    if (isNaN(difference)) return ' (-- %)'
    let plus = difference > 0 ? '+' : '';
    return ` (${plus}${difference.toFixed(1)}%)`
  }

  isAssetSufficient(coin) {
    let difference = this.coinDifference(coin)

    // e.g. if difference is 0.3 (30%), is the balance
    // between 0.7 (70%) and 1.3 (130%) of average
    return Math.abs(difference) <= BOT_DIFFERENCE;
  }

  calcBalanceErrors() {
    const { assetsUSDT } = this.state

    let errors = [];
    Object.keys(assetsUSDT).forEach(coin => {
      if (!this.isAssetSufficient(coin)) {
        errors.push(this.props.t(`[coinName] balance is more than 30% beyond the average.`).replace("[coinName]", coin));
      }
    });

    if (!errors.length) errors = null;

    this.setState({ insufficientBalances: errors })
  }

  formatColumnsWidth(coins) {
    if (!coins || coins.length <= 1) return '12';
    else if (coins.length === 2) return '6';
    else return '4';
  }



  render() {
    let botListColumn = this.getBotListColumn()
    const { bots, isComponent, balance } = this.props
    const {
      updateModalVisible,
      updatingBot,
      insufficientBalances
    } = this.state

    const coins = updatingBot ? updatingBot.trade_coins : [];

    return (
      <React.Fragment>
        <div className={isComponent ? '' : 'page-content'}>
          <MetaTags>
            <title>Atlantis | {this.props.t("My Bots")}</title>
          </MetaTags>

          {!isComponent && <Breadcrumbs title={this.props.t("My Bots")} />}

          <Row className="mb-2">
            <Col sm="4">
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link to="/create-bot">
                  <Button
                    type="button"
                    color="success"
                    className="btn-rounded mb-2 me-2">
                    <i className="mdi mdi-plus me-1" />{" "}
                    {this.props.t("Create New Bot")}
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>

          {this.deleteBotModal()}

          <Modal
            isOpen={updateModalVisible}
            toggle={this.toggleUpdateModal}
            style={{ maxWidth: '600px' }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {updatingBot && updatingBot.status ?
                  this.props.t("Stop Bot") : this.props.t("Start Bot")}
              </h5>
              <button
                type="button"
                onClick={() =>
                  this.setState({ updateModalVisible: false })
                }
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>


            {!this.state.loadingBalance ? (

              <div className="modal-body">
                <h5>{updatingBot && updatingBot.status ?
                  this.props.t("Stop running this bot?") : this.props.t("Start this bot?")}</h5>

{
                  updatingBot &&  !updatingBot.status ?
                <Row className="my-4">

                  <div style={{ marginBottom: '1rem' }}>
                    <p><span className="text-primary">{this.props.t("Assets Average")}:</span> {this.state.assetsAverage && this.state.assetsAverage.toLocaleString() + ' USDT'}</p>
                    <p><span className="text-primary">{this.props.t("Allowed Difference from Average")}:</span> {(BOT_DIFFERENCE * 100) + '%'}</p>
                    <p><span className="text-primary">{this.props.t("Pairs")}:</span> {updatingBot?.pairs?.length && updatingBot.pairs.join(', ')}</p>
                  </div>

                  <Label>{this.props.t("Balance Required")}</Label>
                  {(Object.keys(balance)?.length && coins && coins.length) ? coins.map(coin => {
                    console.log("rrrrrrrrrrrrr",coin)
                    return (

                      <Col sm={this.formatColumnsWidth(coins)} key={coin._id}>
                        <Label className="card-radio-label mb-2">
                          <div className="card-radio coin-balance"
                            style={this.isAssetSufficient(coin.coin_type) ? {} : {
                              borderColor: red
                            }}
                          >
                            <div className="d-flex justify-content-start align-items-center">
                              <i className={`mdi ${icons[coin.coin_type]} font-size-24 text-warning align-middle me-2`} />{" "}
                              <span>{coin.coin_type}</span>
                              <span style={{
                                marginLeft: 'auto',
                                fontWeight: '400',
                                color: this.isAssetSufficient(coin.coin_type) ? green : red
                              }}>{balance[coin.coin_type] + this.displayCoinDifference(coin.coin_type)}</span>
                            </div>
                          </div>
                        </Label>
                      </Col>
                    )
                  }) : ''}
                </Row>
                :
                null
            }

                {insufficientBalances && insufficientBalances.map((error, index) => {
                  return (
                    <Alert color="danger" role="alert" key={index}>
                      {error}
                    </Alert>
                  )
                })}
              </div>

            ) : (
                <div className="p-4">
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                  <span>{this.props.t("Loading balance...")}</span>
                </div>
              )}


            <div className="modal-footer">
              <button
                type="button"
                onClick={this.toggleUpdateModal}
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                {this.props.t("Close")}
                </button>
              <button
                type="button"
                className={`btn btn-${updatingBot && updatingBot.status ? 'danger' : 'success'}`}
                onClick={this.onConfirmStartStop}
                disabled={
                  this.state.loadingBalance ||
                  insufficientBalances
                }
              >
                {updatingBot && updatingBot.status ?
                  this.props.t("Stop") : this.props.t("Start")}
              </button>
            </div>
          </Modal>

          <Card>
            <CardBody>
              <div className="mb-4 h4 card-title" style={{ justifyContent: "space-between", display: "flex", flexDirection: "row" }}>
                <p>{this.props.t("My Bots")}</p>
              </div>

              {this.state.loadingBotChange && (
                <div className="p-4">
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                  <span>{this.props.t("Waiting for bot...")}</span>
                </div>
              )}

              {this.state.success && (
                <Alert color="success" role="alert">
                  {this.state.success}
                </Alert>
              )}

              {this.state.error && (
                <Alert color="danger" role="alert">
                  {this.state.error}
                </Alert>
              )}

              {bots && bots?.length ? (
                <React.Fragment>
                  <div className="table-responsive">
                    <BootstrapTable
                      responsive
                      bordered={true}
                      striped={false}
                      data={bots}
                      columns={botListColumn}
                      keyField="_id"
                      classes={
                        "table align-middle table-nowrap table-check bot-table"
                      }
                      headerWrapperClasses={"table-light"}
                    />
                  </div>
                  {/* <Alert color="warning" role="alert" className="mt-3">
                    <span className="fw-bold">Note:</span> If you would like to delete a bot, please contact customer support.
                  </Alert> */}
                </React.Fragment>
              ) : (
                  <div>
                    <p className="text-muted">{this.props.t("You have not created any bots.")}</p>
                  </div>
                )}
            </CardBody>
          </Card>

        </div>
      </React.Fragment>
    )
  }
}

BotList.propTypes = {
  isComponent: PropTypes.bool,
  t: PropTypes.any,
  history: PropTypes.any,
  balance: PropTypes.object,
  prices: PropTypes.object,
  bots: PropTypes.array,
  exchangeAPIs: PropTypes.array,
  onGetBalance: PropTypes.func,
  onGetPrices: PropTypes.func,
  onGetBots: PropTypes.func,
  onGetAPIs: PropTypes.func,
  test: PropTypes.func,
  onCreateBot: PropTypes.func,
  onStartStopBot: PropTypes.func,
  onDeleteBot: PropTypes.func,
}

const mapStateToProps = state => ({
  balance: state.user.balance,
  prices: state.user.prices,
  bots: state.bots.bots,
  exchangeAPIs: state.exchangeAPI.exchangeAPIs
})

const mapDispatchToProps = dispatch => ({
  onGetBalance: (botID, onSuccess, onError) => dispatch(getBalance(botID, onSuccess, onError)),
  onGetPrices: (coins, onSuccess, onError) => dispatch(getPrices(coins, onSuccess, onError)),
  onGetBots: page => dispatch(getBots(page)),
  onGetAPIs: page => dispatch(getAPIs()),
  test: bot => dispatch(deleteBotSuccess(bot)),
  onCreateBot: bot => dispatch(createBot(bot)),
  onStartStopBot: (bot, onSuccess, onError) => dispatch(startStopBot(bot, onSuccess, onError)),
  onDeleteBot: (bot, onSuccess, onError) => dispatch(deleteBot(bot, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(BotList)))
