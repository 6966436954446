import React, { Component } from "react"
import PropTypes from "prop-types"
import MetaTags from 'react-meta-tags'
import {
  Alert,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Input,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import classnames from "classnames"
import Select from "react-select"

import Breadcrumbs from "navigation/Breadcrumb"

import axios from 'axios';

import {
  getAPIs,
  getExchanges,
  getBalance,
  getPrices,
  getCoins,
  getPairs,
  createBot
} from "store/actions"



// --------------------
// MARKET DATA

// const ccxt = require('ccxt');
// const paradise = new ccxt.paradise();
// const binance = new ccxt.binance();
// let markets;
// let prices;

// --------------------
// COLORS

const green = '#5fcb80';
const red = '#f0616e';

// --------------------

import { BOT_DIFFERENCE } from './bot_settings'




const icons = {
  BTC: 'mdi-bitcoin',
  ETH: 'mdi-ethereum',
  XRP: 'mdi-bitcoin',
  USDT: 'mdi-currency-usd',
  BUSD: 'mdi-currency-usd',
  USD: 'mdi-currency-usd',
  JPY: 'mdi-currency-jpy',
}

let paradiseData;

class CreateBot extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: "1",
      isMenu: false,

      name: '',
      selectedExchange: null,
      selectedCoins: null,
      prices: null,
      pairs: [],
      filteredPairs: [],
      selectedPairs: null,
      totalBalance: 9148.23,
      delta: 3,

      assetsUSDT: null,
      assetsAverage: null,

      loadingBalance: false,

      loading: false,
      error: null,
      insufficientBalances: null
    }

    this.fetchPrices = this.fetchPrices.bind(this)
    this.fetchPricesSuccess = this.fetchPricesSuccess.bind(this)
    this.fetchPricesError = this.fetchPricesError.bind(this)
    this.onGetBalanceSuccess = this.onGetBalanceSuccess.bind(this)
    this.onGetBalanceError = this.onGetBalanceError.bind(this)

    this.toggleTab = this.toggleTab.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.calculatePairs = this.calculatePairs.bind(this)
    this.calculateAssetsAverage = this.calculateAssetsAverage.bind(this)
    this.coinDifference = this.coinDifference.bind(this)
    this.displayCoinDifference = this.displayCoinDifference.bind(this)
    this.isAssetSufficient = this.isAssetSufficient.bind(this)
    this.calcBalanceErrors = this.calcBalanceErrors.bind(this)
    this.formatBalanceColor = this.formatBalanceColor.bind(this)
    this.formatColumnsWidth = this.formatColumnsWidth.bind(this)
    this.formatCoinList = this.formatCoinList.bind(this)

    this.createBot = this.createBot.bind(this)
    this.onSuccess = this.onSuccess.bind(this)
    this.onError = this.onError.bind(this)

    this.redirectToAPIs = this.redirectToAPIs.bind(this)
  }

  componentDidMount() {
    this.props.onGetAPIs()
    // this.props.onGetCoins()
    // this.props.onGetPairs()
    // this.loadMarkets()
  }

  // async loadMarkets() {
  //   markets = await binance.load_markets();
  //   console.log(markets);
  //   // markets = await fetch('https://api.paradise.exchange/spot/api/v3.2/market_summary', {
  //   //   method: 'GET',
  //   //   mode: 'cors',
  //   //   headers: {
  //   //     'Content-Type': 'application/json'
  //   //   }
  //   // }).then(response => {
  //   //   console.log(response);
  //   // });
  // }

  async fetchPrices(coins) {
    if (!coins || coins.length < 2) return;

    let coinsArray = []
    coins.forEach(coin => {
      if (coin.label && coin.label != 'USDT') {
        coinsArray.push(coin.label + '/USDT')
      }
    });

    this.props.onGetPrices({coins:coinsArray,api_id:this.state.selectedExchange.value}, this.fetchPricesSuccess, this.fetchPricesError)

    // let prices = await paradise.fetchTrades(coinsArray, undefined, 20, {});
    // let prices = await binance.fetchTickers(coinsArray);
    // this.setState({ prices })
  }

  fetchPricesSuccess() {
    this.calculateAssetsAverage()
  }

  fetchPricesError() {
    this.calculateAssetsAverage()
  }





  formatExchanges() {
    const { exchangeAPIs } = this.props;
    if (!exchangeAPIs || !exchangeAPIs.length) return []

    return [
      {
        label: '',
        options: exchangeAPIs.map(api => {
          return { label: api.name + ` (${api.exchange_id?.name})`, value: api._id }
        })
      }
    ]
  }

  formatCoinList() {
    const { coins } = this.props;
    if (!coins || !coins.length) return []

    return [
      {
        label: '',
        options: coins.map(coin => {
          return { label: coin.short_name, value: coin._id }
        })
      }
    ]
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  toggleMenu() {
    this.setState(prevState => ({
      isMenu: !prevState.isMenu,
    }))
  }

  calculatePairs(selectedCoins) {
    if (!selectedCoins || selectedCoins.length < 2 || !this.props.pairs?.length) return;

    // let existingPairs = {};
    // let coinPairs = [];
    //
    // selectedCoins.forEach((firstCoin, firstIndex) => {
    //   selectedCoins.forEach((secondCoin, secondIndex) => {
    //
    //     if (firstCoin === secondCoin) return;
    //
    //     // Sort pair name alphabetically
    //     let pairLabel = firstCoin.label + '/' + secondCoin.label;
    //     let pairValue = firstCoin.label + '-' + secondCoin.label;
    //     if (secondCoin.value < firstCoin.value) {
    //       pairLabel = secondCoin.label + '/' + firstCoin.label;
    //       pairValue = secondCoin.label + '-' + firstCoin.label;
    //     }
    //
    //     if (existingPairs[pairLabel]) return;
    //     else {
    //       existingPairs[pairLabel] = true;
    //       coinPairs.push({ label: pairLabel, value: pairValue });
    //     }
    //
    //   });
    // });

    // console.log('selectedCoins', selectedCoins);

    const { pairs } = this.props

    let allPossiblePairs = {}
    selectedCoins.forEach(firstCoin => {
      selectedCoins.forEach(secondCoin => {
        allPossiblePairs[`*${firstCoin.label}-${secondCoin.label}*`] = true
        allPossiblePairs[`*${secondCoin.label}-${firstCoin.label}*`] = true
      });
    });

    allPossiblePairs = Object.keys(allPossiblePairs).join(',')

    // console.log('allPossiblePairs', allPossiblePairs);

    let coinPairs = pairs.filter(pair => allPossiblePairs.includes(`*${pair.pair}*`))

    // console.log('coinPairs', coinPairs);

    coinPairs.sort((first, second) => first.pair < second.pair);

    let pairLabels = [{
      label: '',
      options: coinPairs.map(pair => {
        return {
          label: pair.pair.replace('-', '/'),
          value: pair._id
        }
      })
    }]

    this.setState({ pairs: pairLabels })
  }


  calculateAssetsAverage() {
    const { prices } = this.props
    const coins = this.state.selectedCoins?.map(coin => coin.label)
    const { balance } = this.props

    let error = '';
    if (!coins || !coins.length) {
      error += this.props.t("No coins.")+' '
    }
    if (!prices) {
      error += this.props.t("No prices.")+' '
    }
    if (!balance) {
      error += this.props.t("No balance.")+' '
    }

    if (error.trim()) {
      this.setState({ error })
      return;
    }
    else {
      this.setState({ error: null })
    }

    let assetsUSDT = {}
    coins.forEach(coin => {
      // console.log(coin);
      if (coin == 'USD' || coin == 'USDT') {
        assetsUSDT[coin] = balance[coin]
      }
      else {
        // console.log(prices[coin + '/USDT']);
        assetsUSDT[coin] = balance[coin] * prices[coin + '/USDT']?.price
      }
    });

    let values = Object.values(assetsUSDT)
    let total = values.reduce((x, i) => x + i);
    let assetsAverage = total / values.length;

    this.setState({ assetsUSDT, assetsAverage })

    this.calcBalanceErrors()
    // setTimeout(() => {
    // }, 100)
  }

  coinDifference(coin) {
    const { assetsUSDT, assetsAverage } = this.state

    let difference = assetsUSDT?.[coin] / assetsAverage;
    return difference - 1;
  }

  displayCoinDifference(coin) {
    let difference = this.coinDifference(coin) * 100;
    if (isNaN(difference)) return ' (-- %)'
    let plus = difference > 0 ? '+' : '';
    return ` (${plus}${difference.toFixed(1)}%)`
  }

  isAssetSufficient(coin) {
    let difference = this.coinDifference(coin)

    // e.g. if difference is 0.3 (30%), is the balance
    // between 0.7 (70%) and 1.3 (130%) of average
    return Math.abs(difference) <= BOT_DIFFERENCE;
  }

  calcBalanceErrors() {
    const { assetsUSDT } = this.state

    let errors = [];
    Object.keys(assetsUSDT).forEach(coin => {
      if (!this.isAssetSufficient(coin)) {
        errors.push(this.props.t(`[coinName] balance is more than 30% beyond the average.`).replace("[coinName]", coin));
      }
    });

    if (!errors.length) errors = null;

    this.setState({ insufficientBalances: errors })
  }

  formatBalanceColor(coin) {
    return
  }

  formatColumnsWidth() {
    const { selectedCoins } = this.state;
    if (!selectedCoins || selectedCoins.length <= 1) return '12';
    else if (selectedCoins.length === 2) return '6';
    else return '4';
  }

  handleExchangeSelect = selectedExchange => {
    this.setState({
      selectedExchange,
      loadingBalance: true
    })
    console.log("###", selectedExchange);
    this.props.onGetBalance(selectedExchange.value, this.onGetBalanceSuccess, this.onGetBalanceError)
    this.props.onGetCoins({ api_id: selectedExchange.value},
      (res) => { },
      (err) => { })
    this.props.onGetPairs({ api_id: selectedExchange.value })

      
  }
  handleCoinSelect = async (selectedCoins) => {
    await this.fetchPrices(selectedCoins)
    this.calculatePairs(selectedCoins)
    this.setState({
      selectedCoins,
      selectedPairs: null
    })

    // if (!this.props.prices?.length) {
    //   setTimeout(() => {
    //     this.calculateAssetsAverage()
    //   }, 2000);
    // }
    // else {
    //   this.calculateAssetsAverage()
    // }
  }
  handlePairSelect = selectedPairs => {
    this.setState({ selectedPairs })
  }

  onGetBalanceSuccess(response) {
    this.setState({ loadingBalance: false })
  }

  onGetBalanceError(response) {
    this.setState({
      error: response?.data?.msg,
      loadingBalance: false
    })
  }

  createBot() {
    const {
      name,
      delta,
      selectedExchange,
      selectedCoins,
      selectedPairs,
    } = this.state
    const { balance } = this.props


    if (!name.trim()) {
      this.setState({ error: this.props.t('Please enter a name.') })
      return;
    }
    if (!delta) {
      this.setState({ error: this.props.t('Please enter a delta amount (% per trade).') })
      return;
    }
    if (!selectedExchange) {
      this.setState({ error: this.props.t('Please select an exchange.') })
      return;
    }
    if (!selectedCoins || selectedCoins.length < 2) {
      this.setState({ error: this.props.t('Please select two or more coins.') })
      return;
    }
    if (!selectedPairs || !selectedPairs.length) {
      this.setState({ error: this.props.t('Please select one or more pairs.') })
      return;
    }


    this.setState({ loading: true });

    let coin_details = {};
    selectedCoins.forEach(coin => {
      coin_details[coin.label] = balance[coin.label]
    })

    let newBot = {
      exchange_id: selectedExchange,
      name: name,
      api_id: this.state.selectedExchange.value,
      coins: selectedCoins.map(coin => coin.value),
      pairs: selectedPairs.map(pair => pair.label?.replace('/', '-')),
      delta: delta / 100,
      coin_details
    }

    this.props.onCreateBot(newBot, this.onSuccess, this.onError);
  }

  onSuccess() {
    this.setState({ loading: false })
    this.props.history.push('/bot-list')
  }

  onError(response) {
    this.setState({
      loading: false,
      error: response.msg
    })
  }

   // delta value range 
   handleChange=(e)=>{
    if(e.target.value > 10){
      this.setState({ delta: parseFloat(e.target.value) })
      this.setState({ error: this.props.t('Please enter a delta amount (in between 1 to 10).') })
    }else{

      this.setState({ delta: parseFloat(e.target.value) })
      this.setState({ error:""})
    }
    
  }


  redirectToAPIs() {
    this.props.history.push('/new-api')
  }

  render() {
    const {
      selectedPairs,
      selectedCoins,
      exchangesDisplay,
      insufficientBalances,
      assetsUSDT,
      assetsAverage
    } = this.state;
    const { balance } = this.props;

    // let firstCoin, secondCoin;
    // if (selectedPairs) {
    //   [firstCoin, secondCoin] = selectedPairs.label.split('/');
    // }

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.props.t("Atlantis | New Bot")}</title>
          </MetaTags>
          <Container fluid>

            <Breadcrumbs
              title={this.props.t("My Bots")}
              breadcrumbItem={this.props.t("Create New Bot")}
              parent="/bot-list"
            />

            <Card className="mx-auto" style={{ maxWidth: '720px' }}>
              <CardBody>
                {this.state.selectedExchange && (
                  <div className="float-end">
                    <Dropdown
                      isOpen={this.state.isMenu}
                      toggle={this.toggleMenu}
                    >
                      <DropdownToggle
                        type="button"
                        tag="button"
                        className="btn btn-light"
                      >
                        <i className="mdi mdi-wallet me-1" />
                        <span className="d-none d-sm-inline-block ms-1">
                          {this.props.t("Wallet Balance")}{" "}
                          <i className="mdi mdi-chevron-down" />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end dropdown-menu-md"
                        style={{ maxHeight: '600px', overflowY: 'auto' }}
                      >
                        <div className="dropdown-item-text">
                          <div>
                            <p className="text-muted mb-2">
                            {this.props.t("Available Balance")}
                            </p>
                          </div>
                        </div>

                        <DropdownItem divider />

                        {balance && Object.keys(balance).map(coin => {
                          return balance[coin] ? (
                            <DropdownItem href="#" key={coin}>
                              {coin}<span className="float-end">{balance[coin]}</span>
                            </DropdownItem>
                          ) : ''
                        })}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                )}

                <h4 className="card-title mb-4">{this.props.t("New Bot")}</h4>

                <div className="crypto-buy-sell-nav">
                  <Form>

                    {this.props.exchangeAPIs?.length ? (
                      <div className="mb-3 pb-4">
                        <Label>{this.props.t("Exchange")}</Label>
                        <Select
                          value={this.state.selectedExchange}
                          onChange={this.handleExchangeSelect}
                          options={this.formatExchanges()}
                          classNamePrefix="select2-selection"
                          placeholder={this.props.t("Select Exchange")}
                        />
                        <p>&nbsp;</p>
                       
                        

                      </div>
                    ) : (
                      <div className="mb-3 mt-2">
                        <p className="text-muted">{this.props.t("You have not added any API keys.")}</p>
                      </div>
                    )}

                    {this.state.selectedExchange && (
                      <React.Fragment>
                        <div className="mb-3">
                          <Label>{this.props.t("Bot Name")}</Label>
                          <Input type="text" className="form-control"
                            onChange={(e) => this.setState({ name: e.target.value })} />
                        </div>

                        <div className="mb-3">
                          <Label>{this.props.t("Delta per Trade (%)")}</Label>
                          <Input type="number" className="form-control"
                            // defaultValue={3}
                            min={1}
                            maxLength={10}
                            value={this.state.delta}
                            onChange={(e) => this.handleChange(e)}
                            onWheel={(e) => e.target.blur()} />
                        </div>

                        <div className="mb-3 select2-container">
                          <label className="control-label">
                          {this.props.t("Select Coins")}
                          </label>
                          <Select
                            value={this.state.selectedCoins}
                            isMulti={true}
                            onChange={this.handleCoinSelect}
                            options={this.formatCoinList()}
                            classNamePrefix="select2-selection"
                          />
                        </div>

                        {(selectedCoins?.length >= 2) && (
                          <React.Fragment>
                            <Label>{this.props.t("Balance")}</Label>
                            <Row>
                              <p><span className="text-primary">{this.props.t("Assets Average")}</span> {this.state.assetsAverage && this.state.assetsAverage.toLocaleString() + ' USDT'}</p>
                            </Row>

                            <Row className="mb-3">
                              {selectedCoins.map(coin => {
                                return (
                                  <Col sm={this.formatColumnsWidth()} key={coin.value}>
                                    <Label className="card-radio-label mb-2">
                                      <div className="card-radio">
                                        <div className="d-flex justify-content-start align-items-center">
                                          {/* <i className={`mdi ${icons[coin.label]} font-size-24 text-warning align-middle me-2`} />{" "} */}
                                          <span>{coin.label}</span>
                                          <span style={{
                                            marginLeft: 'auto',
                                            fontWeight: '400',
                                            color: this.isAssetSufficient(coin.label) ? green : red
                                          }}>{balance[coin.label] + this.displayCoinDifference(coin.label)}</span>
                                        </div>
                                      </div>
                                    </Label>
                                  </Col>
                                )
                              })}
                            </Row>
                          </React.Fragment>
                        )}

                        {insufficientBalances && insufficientBalances.map((error, index) => {
                          return (
                            <Alert color="danger" role="alert" key={index}>
                              {error}
                            </Alert>
                          )
                        })}

                        {insufficientBalances && (
                          <Alert color="danger" role="alert">
                            {this.props.t("Please adjust your portfolio balances and return to this page.")}
                          </Alert>
                        )}

                        <div className="mb-3 select2-container">
                          <label className="control-label">
                          {this.props.t("Select Pair")}
                          </label>
                          <Select
                            value={selectedPairs}
                            isMulti={true}
                            onChange={this.handlePairSelect}
                            options={this.state.pairs}
                            classNamePrefix="select2-selection"
                          />
                        </div>

                        {/*
                        {(selectedCoins && selectedCoins.length) && (
                          <React.Fragment>

                            <Label>Current Balance</Label>

                            <Row className="mb-3">
                              <Col sm="6">
                                <Label className="card-radio-label mb-2">
                                  <div className="card-radio">
                                    <div className="d-flex justify-content-start align-items-center">
                                      <i className={`mdi ${icons[firstCoin]} font-size-24 text-warning align-middle me-2`} />{" "}
                                      <span>{firstCoin}</span>
                                      <span className="text-muted" style={{
                                        marginLeft: 'auto',
                                        fontWeight: '400'
                                      }}>{balance[firstCoin]}</span>
                                    </div>
                                  </div>
                                </Label>
                              </Col>

                              <Col sm="6">
                                <Label className="card-radio-label mb-2">
                                  <div className="card-radio">
                                    <div className="d-flex justify-content-start align-items-center">
                                      <i className={`mdi ${icons[secondCoin]} font-size-24 text-primary align-middle me-2`} />{" "}
                                      <span>{secondCoin}</span>
                                      <span className="text-muted" style={{
                                        marginLeft: 'auto',
                                        fontWeight: '400'
                                      }}>{balance[secondCoin]}</span>
                                    </div>
                                  </div>
                                </Label>
                              </Col>
                            </Row>

                          </React.Fragment>
                        )}
                        */}
                      </React.Fragment>
                    )}


                    {this.state.error && (
                      <Alert color="danger" role="alert">
                        {this.state.error}
                      </Alert>
                    )}

                    {this.props.exchangeAPIs?.length ? (
                      <div className="text-center mt-4">
                        <Alert color="warning" role="alert">
                          <span className="fw-bold">{this.props.t("Warning")}</span> {this.props.t("If you use the same currency more than three times, it may become negative.")}
                          <br/><a target="_blank" rel="noreferrer" href="https://support.atlantisgold.trade/hc/en-us/articles/4417556905875-How-to-choose-currency-pair">{this.props.t("How to choose currency pair?")}</a>
                        </Alert>
                        <Button type="button" color="success"
                          onClick={this.createBot}
                          disabled={
                            !this.state.selectedExchange ||
                            !this.state.name.trim() ||
                            !this.state.delta || this.state.delta>10 ||
                            !this.state.selectedCoins ||
                            !this.state.selectedPairs ||
                            this.state.insufficientBalances
                          }
                        >
                          {this.state.loading ? (
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2 "></i>
                          ) : this.props.t("Create Bot")}
                        </Button>
                      </div>
                    ) : (
                      <div className="text-center mt-4">
                        <Button type="button" color="success"
                          onClick={this.redirectToAPIs}
                        >
                          {this.props.t("Add Exchange API Key")}
                        </Button>
                      </div>
                    )}

                  </Form>
                </div>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

CreateBot.propTypes = {
  t: PropTypes.any,
  history: PropTypes.any,
  exchangeAPIs: PropTypes.array,
  balance: PropTypes.object,
  prices: PropTypes.object,
  coins: PropTypes.array,
  pairs: PropTypes.array,
  exchanges: PropTypes.array,
  onGetAPIs: PropTypes.func,
  onGetBalance: PropTypes.func,
  onGetPrices: PropTypes.func,
  onGetCoins: PropTypes.func,
  onGetPairs: PropTypes.func,
  onCreateBot: PropTypes.func,
}

const mapStateToProps = state => ({
  exchangeAPIs: state.exchangeAPI.exchangeAPIs,
  balance: state.user.balance,
  prices: state.user.prices,
  coins: state.bots.coins,
  pairs: state.bots.pairs,
})

const mapDispatchToProps = dispatch => ({
  onGetAPIs: (onFetch) => dispatch(getAPIs(onFetch)),
  onGetBalance: (api_id, onSuccess, onError) => dispatch(getBalance(api_id, onSuccess, onError)),
  onGetPrices: (payload, onSuccess, onError) => dispatch(getPrices(payload, onSuccess, onError)),
  onGetCoins: (exchange_id) => dispatch(getCoins(exchange_id)),
  onGetPairs: (exchange_id,onFetch) => dispatch(getPairs(exchange_id,onFetch)),
  onCreateBot: (bot, onSuccess, onError) => dispatch(createBot(bot, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(CreateBot)))
