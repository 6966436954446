import React, { Component } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Button
} from "reactstrap"
import MetaTags from 'react-meta-tags'
import ReactApexChart from "react-apexcharts"
import PropTypes from "prop-types";
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"

import Breadcrumbs from "navigation/Breadcrumb"

import CardUser from "./BotDetailComponents/card-user"
import CardWelcome from "./BotDetailComponents/card-welcome"
import WalletBalance from "./BotDetailComponents/wallet-balance"
import OverView from "./BotDetailComponents/overview"
import Transactions from "./BotDetailComponents/openOrders"
import TradeHistory from "./BotDetailComponents/tradeHistory"
import Notifications from "./BotDetailComponents/notifications"
import BuySell from "./BotDetailComponents/buy-sell"

import {
  getBots,
  getAPIs,
  updateBot,
  getOpenOrders,
  getTradeHistory,
  deleteBot
} from "store/actions"


// Bitcoin Chart
const series1 = [
  { name: "BTC", data: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14] },
]
const options1 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#f1b44c"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
}

// Ethereum Chart
const series2 = [
  { name: "ETH", data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54] },
]
const options2 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#3452e1"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
}

// LiteCoin Chart
const series3 = [
  { name: "LTC", data: [35, 53, 93, 47, 54, 24, 47, 75, 65, 19, 14] },
]
const options3 = {
  chart: { sparkline: { enabled: !0 } },
  stroke: { curve: "smooth", width: 2 },
  colors: ["#50a5f1"],
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: !1,
      opacityFrom: 0.45,
      opacityTo: 0.05,
      stops: [25, 100, 100, 100],
    },
  },
  tooltip: { fixed: { enabled: !1 }, x: { show: !1 }, marker: { show: !1 } },
}

class BotDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingOrders: true,

      reports: [
        {
          title: "Annual Interest Pace",
          icon: "mdi mdi-bitcoin",
          color: "warning",
          value: "$ 57,86.76",
          arrow: 'mdi-arrow-up text-success',
          desc: "+ 0.0012 ( 0.2 % )",
          series: series1,
          options: options1,
        },
        {
          title: "Total Contractor",
          icon: "mdi mdi-ethereum",
          color: "primary",
          arrow: 'mdi-arrow-down text-danger',
          value: "$ 2,077.53",
          desc: "- 4.102 ( 0.1 % )",
          series: series2,
          options: options2,
        },
        {
          title: "BP",
          icon: "mdi mdi-litecoin",
          color: "info",
          arrow: 'mdi-arrow-up text-success',
          value: "$ 225.01",
          desc: "+ 1.792 ( 0.1 % )",
          series: series3,
          options: options3,
        },
        {
          title: "Trading Volume",
          icon: "mdi mdi-litecoin",
          color: "info",
          arrow: 'mdi-arrow-up text-success',
          value: "$ 225.01",
          desc: "+ 1.792 ( 0.1 % )",
          series: series3,
          options: options3,
        },
      ],
      updatingBot: null,
    }

    this.onGetBotsSuccess = this.onGetBotsSuccess.bind(this)
    this.onGetBotsError = this.onGetBotsError.bind(this)
    this.onGetOpenOrdersSuccess = this.onGetOpenOrdersSuccess.bind(this)
    this.onGetOpenOrdersError = this.onGetOpenOrdersError.bind(this)

    this.upDownColors = this.upDownColors.bind(this)

    this.toggleDeleteModal = this.toggleDeleteModal.bind(this)
    this.onToggleDeleteBot = this.onToggleDeleteBot.bind(this)
    this.onConfirmDeleteBot = this.onConfirmDeleteBot.bind(this)
    this.onDeleteSuccess = this.onDeleteSuccess.bind(this)
    this.onDeleteError = this.onDeleteError.bind(this)
  }

  componentDidMount() {
    const { bots, onGetBots, exchangeAPIs, onGetAPIs, onGetOpenOrders, onGetTradeHistory } = this.props;
    if (!bots || !bots.length) {
      onGetBots()
    }
    if (!exchangeAPIs || !exchangeAPIs.length) {
      onGetAPIs()
    }

    onGetOpenOrders(this.props.match.params.id, this.onGetOpenOrdersSuccess, this.onGetOpenOrdersError)
    onGetTradeHistory(this.props.match.params.id, function(response) {
      console.log(response);
    })
  }

  onGetBotsSuccess(response) {
    // console.log(response);
  }

  onGetBotsError(response) {
    // console.log(response);
  }

  onGetOpenOrdersSuccess(response) {
    // console.log(response);
    this.setState({ loadingOrders: false })
  }

  onGetOpenOrdersError(response) {
    // console.log(response);
    this.setState({ loadingOrders: false })
  }


  upDownColors(text) {
    let value = text.split('(')[0];
    value = value.replace('+', '');
    value = value.replace('- ', '-');
    value = parseFloat(value);

    if (value > 0) return '#20c671';
    else if (value < 0) return '#eb3737';
    else return '#000';
  }


  onToggleDeleteBot(bot) {
    // console.log("onToggleDeleteBot called", bot);
    this.setState({ updatingBot: bot });

    this.toggleDeleteModal();
  }

  toggleDeleteModal() {
    this.setState({ deleteModalVisible: !this.state.deleteModalVisible });
  }

  onConfirmDeleteBot() {
    this.props.onDeleteBot(this.state.updatingBot, this.onDeleteSuccess, this.onDeleteError)
    this.setState({ deleteModalVisible: false })
  }

  onDeleteSuccess(response) {
    this.setState({ success: 'Bot successfully deleted.' })
    this.props.history.push('/bot-list')
  }

  onDeleteError(response) {
    if (!response) {
      this.setState({ error: 'Connection Error' })
    }
    else {
      this.setState({ error: response.error })
    }
  }

  deleteBotModal() {
    return (
      <Modal
        isOpen={this.state.deleteModalVisible}
        toggle={this.toggleDeleteModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">Delete Bot</h5>
          <button
            type="button"
            onClick={this.toggleDeleteModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <h5>This is a reminder that once bot is deleted, subscription fee will not be refunded.</h5>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={this.toggleDeleteModal}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
            </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={this.onConfirmDeleteBot}
          >
            Delete
            </button>
        </div>
      </Modal>
    )
  }

  render() {
    const bot = this.props.bots?.find(bot => bot._id == this.props.match.params.id)
    const api = bot?.api_id ? this.props.exchangeAPIs.find(api => api._id == bot.api_id._id) : null
    // console.log("this.props ", this.props);

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Atlantis | {bot && bot.name}</title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs title="My Bots" breadcrumbItem={bot && bot.name} parent="/bot-list"/>

            <Col xl="12">
              <div className="d-flex align-items-center mb-3">
                <h4 className="card-title" style={{ marginRight: 'auto' }}>{this.props.t("Bot Information")}</h4>

                <div className="ms-auto d-inline-flex align-items-center justify-content-center">
                  <i className={`bx font-size-24 ${bot?.is_initialised ? 'bx-check-circle text-success' : 'bx-x-circle text-danger'}`} />
                  <span className={`fw-bold ms-1 ${bot?.is_initialised ? 'text-success' : 'text-danger'}`}>
                    {bot?.is_initialised ? this.props.t("Initialized") : this.props.t("Not Initialized")}
                  </span>
                </div>

                <div className="ms-4 d-inline-flex align-items-center justify-content-center">
                  <p className="mb-0">{this.props.t("Expiration Date")}: {bot?.subscription_expiry ? (new Date(bot?.subscription_expiry)?.toLocaleString()) : this.props.t("Not Subscribed")}</p>
                </div>

                <p className="mb-0 ms-4">{this.props.t("Status")}: <span className={`fw-bold ${bot?.status ? 'text-success' : 'text-danger'}`}>{
                  bot?.status == null ? this.props.t("Loading...") : (bot?.status ? this.props.t("Running") : this.props.t("Stopped"))
                }</span></p>
              </div>

              <Card>
                <CardBody>
                  <Row className="mt-3">
                    <Col xs="6" sm="4">
                      <div>
                        <p className="mb-2">{this.props.t("Delta")}</p>
                        <h5>{bot?.delta ? (bot.delta * 100) + '%' : '--'}</h5>
                      </div>
                    </Col>
                    <Col xs="6" sm="4">
                      <div>
                        <p className="mb-2">{this.props.t("Coin Pairs")}</p>
                        <h5>{bot?.pairs?.join(', ') || '--'}</h5>
                      </div>
                    </Col>
                    <Col xs="6" sm="4">
                      <div>
                        <p className="mb-2">{this.props.t("Trading Fee")}</p>
                        <h5>{bot?.tradingfee ? bot.tradingfee + '%' : '--'}</h5>
                      </div>
                    </Col>
                  </Row>
                  <div className="mt-5">
                    <h6>{this.props.t("Exchange")}: {api?.name ? api.name + ` (${api.exchange_id?.name})` : '--'}</h6>
                  </div>
                  <div className="mt-3 mb-3">
                    <h6>{this.props.t("Created On")}: {bot?.created_date ? new Date(bot.created_date).toLocaleString() : '--'}</h6>
                  </div>

                </CardBody>
              </Card>
            </Col>

            {/*
            <Row md="4" sm="6">
            {this.state.reports.map((report, key) => (

              <Col sm="6" xs="12" key={key} className="d-flex flex-column">
                <Card className="flex-grow-1">
                  <CardBody className="d-flex flex-column" style={{ width: '100%' }}>
                    <div className="d-flex">
                      <i className="fa fa-question-circle" />
                      <h5 className="ml-auto flex-grow-1" style={{ color: this.upDownColors(report.desc), textAlign: 'right', whiteSpace: 'pre' }}>{report.desc.replaceAll(' ', '').replaceAll('(', ' (')}</h5>
                    </div>
                    <p className="text-muted mb-4">
                      {report.title}
                    </p>

                    <Row className="mt-auto">
                      <Col xs="12">
                        <p style={{ textAlign: "center", fontSize: 10 }}>{report.value}</p>
                      </Col>

                      <Col xs="12">
                        <div>
                          <div className="apex-charts">
                            <ReactApexChart
                              options={report.options}
                              series={report.series}
                              type="area"
                              height={40}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

            ))}
            </Row>
            */}

            <Row>
              {/*
                <WalletBalance />
              */}
              {/* <OverView /> */}
            </Row>

            <Row>

            <Transactions
              openOrders={bot?.open_orders}
              loading={this.state.loadingOrders} />

            <TradeHistory
              trades={bot?.trade_history}
              loading={this.state.loadingOrders} />

            {/* <Notifications /> */}
            {/* { <BuySell /> */}
            <Col xs="12">
                <Button className="btn btn-danger btn-sm float-end mb-3" type="button" onClick={() => this.onToggleDeleteBot(bot)} > {this.props.t("Delete Bot")}</Button>
            </Col>
            </Row>

            {/* <Button
            className="btn btn-danger btn-sm float-end"
              type="button"
              style={{ marginLeft: '0.25rem' }}
              onClick={() => this.onToggleDeleteBot(bot)}
            >
              Delete Bot
              </Button> */}
            {this.deleteBotModal()}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}


BotDetail.propTypes = {
  t: PropTypes.any,
  match: PropTypes.any,
  history: PropTypes.any,
  bots: PropTypes.array,
  exchangeAPIs: PropTypes.array,
  onGetBots: PropTypes.func,
  onGetAPIs: PropTypes.func,
  onUpdateBot: PropTypes.func,
  onGetOpenOrders: PropTypes.func,
  onGetTradeHistory: PropTypes.func,
  onDeleteBot: PropTypes.func,
}

const mapStateToProps = state => ({
  bots: state.bots.bots,
  exchangeAPIs: state.exchangeAPI.exchangeAPIs,
})

const mapDispatchToProps = dispatch => ({
  onGetBots: (onSuccess, onError) => dispatch(getBots(null, onSuccess, onError)),
  onGetAPIs: () => dispatch(getAPIs()),
  onUpdateBot: bot => dispatch(updateBot(bot)),
  onGetOpenOrders: (id, onSuccess, onError) => dispatch(getOpenOrders(id, onSuccess, onError)),
  onGetTradeHistory: (id, onSuccess, onError) => dispatch(getTradeHistory(id, onSuccess, onError)),
  onDeleteBot: (bot, onSuccess, onError) => dispatch(deleteBot(bot, onSuccess, onError)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(BotDetail)))
